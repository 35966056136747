:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.ad-container {
  height: 100%;
  width: 100%;
}

.google-ad {
  width: 100%;
}

/* Unforunately we have an issue where Collin's team added 10px margin on the left and right of the Spotlight Ads.
This causes margin issues on some views. This is my work around to properly display the ads  with proper margin on the page layout.*/
:global(.lw-global-theme) .google-ad {
  width: calc(100% + 20px);
  position: relative;
  right: 10px;
}

:global(.laf-global-theme) .google-ad {
  width: calc(100% + 20px);
  position: relative;
  right: 10px;
}

.hide-ad {
  display: none;
  height: 0;
  width: 0;
}