:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.market-blurb {
  background-blend-mode: hard-light, normal;
  border-radius: 6px;
  text-align: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  display: block;
  width: 100%;
  gap: 16px;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  -ms-flex-item-align: center;
      align-self: center;
  margin-bottom: 24px;
}
@media only screen and (min-width: 601px) {
  .market-blurb {
    width: 480px;
  }
}
@media only screen and (min-width: 1025px) {
  .market-blurb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1;
        flex: 1 1;
    margin-bottom: 0;
    width: 100%;
    text-align: left;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-item-align: start;
        align-self: flex-start;
  }
}
.market-blurb a:hover {
  color: #fff;
  cursor: pointer;
  -webkit-text-decoration: 2px #fff underline;
          text-decoration: 2px #fff underline;
}
.market-blurb .content-container {
  padding: 0 16px 16px 16px;
  width: 100%;
  min-height: auto;
  height: auto;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: center;
}
.market-blurb .title {
  font-family: Athelas, serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  color: #fff;
  margin: 0px auto;
  margin-bottom: 32px;
}
.market-blurb .title span {
  display: block;
}
.market-blurb .content {
  font-size: 14px;
  line-height: 20px;
  margin: 0px auto;
  color: #fff;
  font-weight: 400;
}
.market-blurb .content span {
  display: block;
}
.market-blurb .desktop {
  display: none;
}

@media only screen and (min-width: 601px) {
  .market-blurb .title {
    font-size: 32px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    margin-bottom: 32px;
  }
  .market-blurb .content {
    line-height: 24px;
    font-size: 16px;
  }
}
@media only screen and (min-width: 1025px) {
  .market-blurb {
    min-height: 380px;
    height: 380px;
    -ms-flex-pack: center;
        justify-content: center;
  }
  .market-blurb .title {
    text-align: left;
  }
  .market-blurb .content-container {
    text-align: left;
    padding: 24px 16px 0 0;
  }
}
@media only screen and (min-width: 1401px) {
  .market-blurb {
    min-height: 380px;
    height: 380px;
  }
  .market-blurb .content-container {
    padding: 40px;
    text-align: left;
    min-height: 380px;
    height: 380px;
  }
}