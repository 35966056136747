:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.status-pill {
  color: #0e0d0c;
  background-color: white;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  font-weight: 500;
  white-space: pre;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
}

.dot {
  display: -ms-flexbox;
  display: flex;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin: 0 8px 0 0;
}
.dot.free {
  margin: 0 4px 0 0;
}
@media only screen and (min-width: 601px) {
  .dot.free {
    margin: 0 8px 0 0;
  }
}
.dot.under-contract {
  background: #af5a1d;
}
.dot.sold, .dot.off-market {
  background: #757575;
}

:global(.lw-global-theme) .status-pill {
  color: black;
  border-radius: 3px;
  font-family: "Open Sans", "Segoe UI", Tahoma, sans-serif;
  font-weight: 600;
}

:global(.laf-global-theme) .status-pill {
  color: black;
  border-radius: 0;
  font-family: Poppins, sans-serif;
  font-weight: 500;
}