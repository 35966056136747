:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.main {
  display: inline-block;
  width: 50%;
  height: 90px;
  padding: 16px 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 4px;
}
.main:hover .name {
  color: #006d31;
}
@media only screen and (min-width: 901px) {
  .main {
    width: 25%;
  }
}

.info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: start;
      justify-content: flex-start;
  width: 100%;
  cursor: pointer;
}

.name {
  margin: 0;
  text-align: left;
  color: #333333;
  font-weight: bold;
}

.listings,
.index {
  margin: 0;
  text-align: left;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
}