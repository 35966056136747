:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.placard-image-overlay {
  position: absolute;
  overflow: hidden;
  bottom: 0;
  left: 0;
  width: 100%;
  color: white;
  padding: 13px 7px;
  height: 25%;
  display: -ms-flexbox;
  display: flex;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.0001)), to(#000000));
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, #000000 100%);
  -ms-flex-pack: start;
      justify-content: start;
  -ms-flex-align: end;
      align-items: end;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  pointer-events: none;
}
.placard-image-overlay > * {
  pointer-events: auto;
}

.pill {
  padding: 0 0 12px 12px;
}
@media only screen and (min-width: 601px) {
  .pill {
    padding: 0 0 16px 16px;
  }
}

.free-listing {
  -ms-flex-pack: center;
      justify-content: center;
  padding: 0 0 12px 0;
  overflow: visible;
}
@media only screen and (min-width: 601px) {
  .free-listing {
    -ms-flex-pack: start;
        justify-content: start;
    padding: 0 0 16px 16px;
  }
}

:global(.lw-global-theme) .icons {
  padding: 0;
}

:global(.laf-global-theme) .icons {
  padding: 0;
}