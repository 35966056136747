:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.main {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #fff;
  z-index: 11;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  position: relative;
  width: 100%;
  top: 0;
  position: sticky;
}

@media only screen and (min-width: 901px) {
  .unstick {
    top: unset;
    position: relative;
  }
}

:global(.loa-global-theme) .main {
  display: none;
  background: #fff;
}
@media only screen and (min-width: 601px) {
  :global(.loa-global-theme) .main {
    display: none;
    border-bottom: 1px solid #c2c2c2;
    -webkit-box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px;
            box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px;
    height: 56px;
    padding: 8px 8px 0px 8px;
  }
}
@media only screen and (min-width: 901px) {
  :global(.loa-global-theme) .main {
    padding: 16px 8px;
    display: -ms-flexbox;
    display: flex;
  }
}

:global(.lw-global-theme) .main {
  height: 71px;
  border-bottom: 1px solid #cccccc;
}

:global(.laf-global-theme) .main {
  height: 71px;
  border-bottom: 1px solid #cccccc;
}