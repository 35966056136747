:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.main {
  height: 100%;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  top: 0;
  width: 100%;
  z-index: 10;
  opacity: 0.6;
}

.ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 11px;
}

.ellipsis div {
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #fff;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.ellipsis div.gray {
  background: #888888;
}
.ellipsis div.black {
  background: #333333;
}
.ellipsis div.big {
  width: 21px;
  height: 21px;
}

.ellipsis div:nth-child(1) {
  left: 6px;
  -webkit-animation: ellipsis1 0.6s infinite;
          animation: ellipsis1 0.6s infinite;
}
.ellipsis div:nth-child(1).big {
  left: -10px;
}

.ellipsis div:nth-child(2) {
  left: 6px;
  -webkit-animation: ellipsis2 0.6s infinite;
          animation: ellipsis2 0.6s infinite;
}
.ellipsis div:nth-child(2).big {
  left: -3px;
}

.ellipsis div:nth-child(3) {
  left: 26px;
  -webkit-animation: ellipsis2 0.6s infinite;
          animation: ellipsis2 0.6s infinite;
}
.ellipsis div:nth-child(3).big {
  left: 23px;
}

.ellipsis div:nth-child(4) {
  left: 45px;
  -webkit-animation: ellipsis3 0.6s infinite;
          animation: ellipsis3 0.6s infinite;
}
.ellipsis div:nth-child(4).big {
  left: 52px;
}

@-webkit-keyframes ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0);
  }
}
@keyframes ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0);
  }
}