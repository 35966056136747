:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.placard-image {
  position: relative;
  -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
  overflow: hidden;
  aspect-ratio: 1.6326530612;
  width: 100%;
}
.placard-image .placard-picture {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

@media only screen and (min-width: 601px) {
  .placard-image .placard-picture {
    width: 100%;
    height: 100%;
    aspect-ratio: 1.5;
  }
}
:global(.PropertySearch) .level-free.placard-image {
  height: 104px;
  width: 104px;
  aspect-ratio: 1;
}
:global(.PropertySearch) .level-free.placard-image .placard-picture {
  width: auto;
  height: 100%;
  aspect-ratio: 1;
}
:global(.PropertySearch) .level-premium.placard-image,
:global(.PropertySearch) .level-standard.placard-image {
  height: 166px;
}
:global(.PropertySearch) .level-signature.placard-image,
:global(.PropertySearch) .level-showcase.placard-image {
  height: 187px;
}
:global(.PropertySearch) .level-gold.placard-image {
  height: 216px;
}
:global(.PropertySearch) .level-platinum.placard-image {
  height: 256px;
}
:global(.PropertySearch) .level-platinum.placard-image img {
  height: 256px;
}
:global(.PropertySearch) .level-diamond.placard-image {
  height: 304px;
}
@media only screen and (min-width: 601px) {
  :global(.PropertySearch) .level-diamond.placard-image {
    height: 360px;
  }
}
@media only screen and (min-width: 1025px) {
  :global(.PropertySearch) .level-diamond.placard-image .placard-picture {
    height: 360px;
  }
}
@media only screen and (min-width: 601px) {
  :global(.PropertySearch) .level-free.placard-image {
    max-width: 178px;
    width: unset;
    height: unset;
    min-width: 202px;
    aspect-ratio: 1.5;
  }
  :global(.PropertySearch) .level-free.placard-image .placard-picture {
    width: 100%;
    height: 100%;
    aspect-ratio: 1.5;
  }
  :global(.PropertySearch) .level-premium.placard-image,
  :global(.PropertySearch) .level-standard.placard-image {
    min-width: 244px;
    aspect-ratio: 1.5;
  }
  :global(.PropertySearch) .level-signature.placard-image,
  :global(.PropertySearch) .level-showcase.placard-image {
    min-width: 278px;
    aspect-ratio: 1.5;
  }
  :global(.PropertySearch) .level-gold.placard-image {
    min-width: 320px;
    width: 320px;
    height: 216px;
    aspect-ratio: 1.4814814815;
  }
  :global(.PropertySearch) .level-gold.placard-image .placard-picture {
    aspect-ratio: 1.4814814815;
  }
  :global(.PropertySearch) .level-platinum.placard-image {
    min-width: 368px;
    width: 368px;
    height: 256px;
    aspect-ratio: 1.4375;
  }
  :global(.PropertySearch) .level-platinum.placard-image .placard-picture {
    aspect-ratio: 1.4375;
  }
}
@media only screen and (min-width: 990px) and (max-width: 1024px) {
  :global(.PropertySearch) .level-free.map-view.placard-image {
    min-width: none;
    height: 104px;
    width: 104px;
    aspect-ratio: 1;
  }
  :global(.PropertySearch) .level-free.map-view.placard-image .placard-picture {
    width: 104px;
    height: 100%;
    aspect-ratio: 1;
  }
  :global(.PropertySearch) .level-gold.map-view.placard-image {
    min-width: none;
    width: 100%;
    aspect-ratio: 1.4814814815;
  }
  :global(.PropertySearch) .level-gold.map-view.placard-image .placard-picture {
    width: 100%;
  }
  :global(.PropertySearch) .level-diamond.map-view.placard-image {
    height: 304px;
  }
}

.level-free-map-click-card.placard-image {
  min-width: auto;
  height: 104px;
  width: 104px;
  aspect-ratio: 1;
}
.level-free-map-click-card.placard-image .placard-picture {
  width: auto;
  height: 100%;
  aspect-ratio: 1;
}