:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.ellipsis-after-2-lines {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

.ellipsis-after-3-lines {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

.ellipsis-after-4-lines {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

.placard-container {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  border-radius: 6px;
  overflow: hidden;
  outline: 1px solid #cbcbcb;
  -webkit-transition: outline 0.3s;
  transition: outline 0.3s;
  background: #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: calc(100% - 20px);
  text-align: left;
  width: 100%;
}
.placard-container p {
  margin: 0;
}
.placard-container a {
  color: #333;
  text-decoration: none;
}
.placard-container.hover {
  outline: 2px solid #0e7080;
  -webkit-transition: outline 0.3s;
  transition: outline 0.3s;
}

.loading {
  background: #fff;
  position: absolute;
}

:global(.PropertySearch) .placard-container {
  width: 100%;
}
:global(.PropertySearch) .placard-container.level-free, :global(.PropertySearch) .placard-container.level-free-map-click-card {
  -ms-flex-direction: row;
      flex-direction: row;
  height: 104px;
}
:global(.PropertySearch) .placard-container.level-diamond {
  -ms-flex-direction: column;
      flex-direction: column;
}
@media only screen and (min-width: 601px) {
  :global(.PropertySearch) .placard-container.level-premium, :global(.PropertySearch) .placard-container.level-standard, :global(.PropertySearch) .placard-container.level-signature, :global(.PropertySearch) .placard-container.level-showcase, :global(.PropertySearch) .placard-container.level-gold, :global(.PropertySearch) .placard-container.level-platinum, :global(.PropertySearch) .placard-container.level-free {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  :global(.PropertySearch) .placard-container.level-free {
    height: 136px;
  }
  :global(.PropertySearch) .placard-container.level-premium, :global(.PropertySearch) .placard-container.level-standard {
    height: 164px;
  }
  :global(.PropertySearch) .placard-container.level-signature, :global(.PropertySearch) .placard-container.level-showcase {
    height: 187px;
  }
  :global(.PropertySearch) .placard-container.level-gold {
    height: 216px;
  }
  :global(.PropertySearch) .placard-container.level-platinum {
    height: 256px;
  }
}
@media only screen and (min-width: 990px) and (max-width: 1024px) {
  :global(.PropertySearch) .placard-container.map-view.level-premium, :global(.PropertySearch) .placard-container.map-view.level-standard, :global(.PropertySearch) .placard-container.map-view.level-signature, :global(.PropertySearch) .placard-container.map-view.level-showcase, :global(.PropertySearch) .placard-container.map-view.level-gold, :global(.PropertySearch) .placard-container.map-view.level-platinum {
    height: 100%;
    width: 100%;
    margin-left: 2px;
    margin-right: 2px;
    -ms-flex-direction: column;
        flex-direction: column;
  }
  :global(.PropertySearch) .placard-container.map-view.level-free {
    -ms-flex-direction: row;
        flex-direction: row;
    height: 104px;
    width: 100%;
    margin-left: 2px;
    margin-right: 2px;
  }
  :global(.PropertySearch) .placard-container.map-view.level-diamond {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media only screen and (min-width: list-view-map-view-screen) {
  .placard-container {
    width: 100%;
  }
}
:global(.loa-global-theme) :global(.Home) .placard-container {
  width: 100%;
}
:global(.loa-global-theme) :global(.PropertyDetail) .placard-container {
  width: 100%;
}

:global(.lw-global-theme) .placard-container.hover {
  outline: 2px solid #0e0d0c;
  -webkit-transition: outline 0.3s;
  transition: outline 0.3s;
}

:global(.laf-global-theme) .placard-container.hover {
  outline: 2px solid #c4c4c4;
  -webkit-transition: outline 0.3s;
  transition: outline 0.3s;
}

.placard-container.level-free-map-click-card {
  height: 100%;
  -ms-flex-direction: row;
      flex-direction: row;
}
.placard-container.level-showcase-map-click-card {
  -ms-flex-direction: column;
      flex-direction: column;
  height: 280px;
  margin: 0px 0px 0px 0px;
}
.placard-container.level-free-map-click-card, .placard-container.level-showcase-map-click-card {
  border-radius: 6px 6px 0px 0px;
  margin: 0px !important;
  width: 100%;
}
.placard-container.level-free-map-click-card.hover, .placard-container.level-showcase-map-click-card.hover {
  outline: 1px solid #cbcbcb;
}
@media only screen and (min-width: 601px) {
  .placard-container.level-free-map-click-card, .placard-container.level-showcase-map-click-card {
    border-radius: 6px;
    margin: 0px;
  }
}