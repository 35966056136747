:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

@media only screen and (min-width: 601px) {
  :global(.loa-global-theme) .broker-for-diamond-search-result {
    display: none;
  }
}
@media only screen and (min-width: 1025px) {
  :global(.loa-global-theme) .broker-for-diamond-search-result-map {
    display: none;
  }
}