:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.button {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: transparent;
  border-radius: 3px;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  cursor: pointer;
  font-size: 1em;
  height: 48px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  padding: 0 10px;
  text-align: center;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  margin: 0;
}
.button:focus {
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.button:disabled {
  opacity: 0.4;
}

.is-circle {
  width: 40px;
  height: 40px;
  display: inline-block;
  -ms-flex-item-align: center;
      align-self: center;
  font-size: 17px;
  cursor: pointer;
  font-weight: 400;
  padding: 0.2em;
  border-radius: 100%;
  border: 1px solid #aaaaaa;
}

.is-primary,
.is-inverted,
.is-secondary,
.is-tertiary {
  cursor: pointer;
}

.is-primary:hover,
.is-inverted:hover,
.is-circle:hover,
.is-tertiary:hover {
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
}

.is-secondary:hover {
  background: rgba(255, 255, 255, 0.2);
}
.is-secondary:active {
  background: rgba(14, 13, 12, 0.2);
}

:global(.loa-global-theme) .is-primary,
:global(.loa-global-theme) .is-inverted,
:global(.loa-global-theme) .is-tertiary {
  font-family: Roboto, "Franklin Gothic Medium", Tahoma, sans-serif;
  border-radius: 6px;
  font-weight: 500;
}
:global(.loa-global-theme) .is-primary {
  color: white;
  background-color: #006d31;
  border: 1px solid #006d31;
}
:global(.loa-global-theme) .is-primary:hover {
  background-color: #155443;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}
:global(.loa-global-theme) .is-primary:active {
  background-color: #253732;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}
:global(.loa-global-theme) .is-tertiary {
  background-color: transparent;
  border: none;
  color: #0e7080;
  padding: 4px 8px 4px 4px;
  border-radius: 6px;
  -webkit-transition: background-color 100ms ease-out;
  transition: background-color 100ms ease-out;
}
:global(.loa-global-theme) .is-tertiary:hover {
  background: rgba(14, 112, 128, 0.08);
}
:global(.loa-global-theme) .is-tertiary:active {
  background: rgba(14, 112, 128, 0.12);
}
:global(.loa-global-theme) .is-gradient {
  border-radius: 8px;
  background: -webkit-gradient(linear, left top, right top, color-stop(0.01%, rgba(14, 124, 53, 0.9)), to(rgba(19, 71, 74, 0.9))), #006d31;
  background: linear-gradient(90deg, rgba(14, 124, 53, 0.9) 0.01%, rgba(19, 71, 74, 0.9) 100%), #006d31;
}
:global(.loa-global-theme) .is-gradient:hover {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(37, 55, 50, 0.4)), to(rgba(37, 55, 50, 0.4))), -webkit-gradient(linear, left top, right top, color-stop(0.01%, rgba(14, 124, 53, 0.9)), to(rgba(19, 71, 74, 0.9))), #006d31;
  background: linear-gradient(0deg, rgba(37, 55, 50, 0.4) 0%, rgba(37, 55, 50, 0.4) 100%), linear-gradient(90deg, rgba(14, 124, 53, 0.9) 0.01%, rgba(19, 71, 74, 0.9) 100%), #006d31;
}
:global(.loa-global-theme) .is-gradient:active {
  background: #253732;
}
:global(.loa-global-theme) .is-inverted {
  color: #302c2d;
  border: 1px solid #302c2d;
  background-color: #fff;
}
:global(.loa-global-theme) .is-inverted:hover {
  background-color: rgba(203, 203, 203, 0.2);
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}
:global(.loa-global-theme) .is-inverted:active {
  background-color: rgba(203, 203, 203, 0.3);
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}

:global(.lw-global-theme) .is-primary,
:global(.lw-global-theme) .is-inverted,
:global(.lw-global-theme) .is-secondary {
  font-family: "Open Sans", "Segoe UI", Tahoma, sans-serif;
}
:global(.lw-global-theme) .is-primary {
  color: white;
  background-color: #fa6401;
  border: 1px solid #fa6401;
}
:global(.lw-global-theme) .is-primary:hover {
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
}
:global(.lw-global-theme) .is-inverted,
:global(.lw-global-theme) .is-secondary {
  font-weight: 600;
  background: none;
}
:global(.lw-global-theme) .is-inverted:hover,
:global(.lw-global-theme) .is-secondary:hover {
  color: white;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}
:global(.lw-global-theme) .is-inverted {
  color: #fa6401;
  border: 2px solid #fa6401;
}
:global(.lw-global-theme) .is-inverted:hover {
  background-color: #fa6401;
}
:global(.lw-global-theme) .is-secondary {
  color: #333333;
  border: 2px solid #333333;
}
:global(.lw-global-theme) .is-secondary:hover {
  background-color: #333333;
}

:global(.laf-global-theme) .is-primary,
:global(.laf-global-theme) .is-secondary,
:global(.laf-global-theme) .is-inverted {
  border-radius: 0;
  font-family: Poppins, sans-serif;
}
:global(.laf-global-theme) .is-primary {
  color: #333333;
  background-color: #d3ae48;
  font-weight: 600;
}
:global(.laf-global-theme) .is-primary:hover {
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
  background-color: #b9983f;
}
:global(.laf-global-theme) .is-secondary,
:global(.laf-global-theme) .is-inverted {
  border: 1px solid black;
  color: black;
  font-weight: normal;
  background: none;
}
:global(.laf-global-theme) .is-secondary:hover,
:global(.laf-global-theme) .is-inverted:hover {
  background-color: black;
  color: white;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}