:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.tag-container {
  display: -ms-flexbox;
  display: flex;
  padding: 0px 4px 0px 8px;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  border-radius: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 20px;
}
.tag-container.decrease {
  background: #155443;
}
.tag-container.increase {
  background: #a82445;
}
.tag-container.increase .icon {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}

.price-change {
  color: #f1f3f4;
  font-family: Roboto, "Franklin Gothic Medium", Tahoma, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.3px;
}

.icon {
  display: inline-block;
  background: url("/assets/images/price-change-arrow.svg") no-repeat center center;
  width: 20px;
  height: 20px;
  background-size: contain;
}