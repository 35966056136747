:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.article-grid-item {
  display: -ms-flexbox;
  display: flex;
  height: 424px;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex: 1 0;
      flex: 1 0;
  border-radius: 12px;
  -webkit-box-shadow: 2px 8px 40px 0px rgba(0, 0, 0, 0.1), 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 2px 8px 40px 0px rgba(0, 0, 0, 0.1), 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.15);
}

.image-container {
  width: 100%;
  height: auto;
}

.article-image {
  height: 175px;
  width: 100%;
  border-radius: 12px 12px 0 0;
  -o-object-fit: cover;
     object-fit: cover;
}

.article-content {
  padding: 24px;
  height: 200px;
  width: calc(100% - 48px);
  display: block;
  border-radius: 0 0 12px 12px;
  -ms-flex-direction: column;
      flex-direction: column;
  background-color: #253732;
}

.article-date {
  color: #302c2d;
  font-family: Roboto, "Franklin Gothic Medium", Tahoma, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #cbcbcb;
}

.article-title {
  color: #302c2d;
  font-family: Roboto, "Franklin Gothic Medium", Tahoma, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.072px;
  color: #fff;
  margin: 0 0 8px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

.article-body-section {
  height: 120px;
  display: block;
  margin: 8px 0 16px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  gap: 8px;
}

.right-arrow-white {
  display: inline-block;
  background: url("/assets/images/land-bundle.svg#arrow-down-white") no-repeat center center;
  width: 24px;
  height: 24px;
  background-size: contain;
  -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.article-body-3,
.article-body-4 {
  color: #0e0d0c;
  font-family: Roboto, "Franklin Gothic Medium", Tahoma, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #fff;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

.article-body-4 {
  height: 80px;
  -webkit-line-clamp: 4;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

.article-body-placeholder {
  height: 80px;
  display: -ms-flexbox;
  display: flex;
}

.article-link {
  display: -ms-flexbox;
  display: flex;
  color: #302c2d;
  font-family: Roboto, "Franklin Gothic Medium", Tahoma, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  padding-top: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.4666666667);
}