:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.embla {
  --slide-spacing: 1rem;
  --slide-size: 100%;
  --slide-height: auto;
  height: auto;
  overflow: hidden;
  position: relative;
  max-width: 1600px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  min-height: 280px;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex: 3 1;
      flex: 3 1;
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
  max-width: 1600px;
  position: relative;
}

.embla__container {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: -ms-flexbox;
  display: flex;
  -ms-touch-action: pan-y;
      touch-action: pan-y;
  gap: 16px;
  height: 320px;
}

.embla__slide {
  -ms-flex: 0 0 var(--slide-size);
      flex: 0 0 var(--slide-size);
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  min-width: 0;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  gap: 16px;
  width: 100%;
}

.lazy-load-container {
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.embla__slide__img {
  width: 100%;
  position: relative;
  height: 100%;
  min-height: 0px;
  cursor: pointer;
}

.embla__slide__title {
  color: white;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.3px;
  text-align: left;
  position: absolute;
  bottom: 16px;
  left: 16px;
}

.image {
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 6px;
  width: 100%;
  height: 100%;
}

.embla__slide__overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 25%;
  border-radius: 6px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.0001)), to(#000000));
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, #000000 100%);
}

.embla__prev,
.embla__next,
.icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.embla__prev,
.embla__next {
  z-index: 5;
  border: none;
  width: 40px;
  height: 40px;
}

.icon {
  opacity: 1;
  z-index: 6;
}

.prev-icon {
  left: 15px;
  display: inline-block;
  background: url("/assets/images/shared-bundle.svg#embla-arrow") no-repeat center center;
  width: 10px;
  height: 18px;
  background-size: contain;
}

.next-icon {
  right: 15px;
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
  display: inline-block;
  background: url("/assets/images/shared-bundle.svg#embla-arrow") no-repeat center center;
  width: 10px;
  height: 18px;
  background-size: contain;
}

.button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  height: 0px;
}

.mobile .embla__next,
.desktop .embla__next {
  border-radius: 3px 0px 0px 3px;
  right: 0;
}
.mobile .embla__prev,
.desktop .embla__prev {
  border-radius: 0px 3px 3px 0px;
  left: 0;
}
.mobile .embla__prev,
.mobile .embla__next,
.desktop .embla__prev,
.desktop .embla__next {
  background: rgba(14, 13, 12, 0.6);
}
.mobile .embla__prev .icon,
.mobile .embla__next .icon,
.desktop .embla__prev .icon,
.desktop .embla__next .icon {
  opacity: 1;
}

.mobile .embla__prev,
.mobile .embla__next {
  opacity: 1;
}

.desktop .embla__prev,
.desktop .embla__next {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.desktop.hover .embla__prev,
.desktop.hover .embla__next {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.one-column .embla__slide .embla__slide__img {
  grid-column-start: 2;
  grid-column-end: 2;
}

.two-column .embla__slide {
  text-align: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.three-column .embla__slide {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.four-column .embla__slide {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}