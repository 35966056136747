:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.search-container {
  display: -ms-flexbox;
  display: flex;
}
@media only screen and (min-width: 901px) {
  .search-container {
    width: calc(100% - 30px);
    padding: 10px 15px;
  }
}
@media only screen and (min-width: 1401px) {
  .search-container {
    width: 79%;
    max-width: 1024px;
  }
}

.non-home-search-bar-styles {
  padding: 10px 15px;
}

.range {
  min-width: 125px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

.text {
  margin-top: 2px;
}

.search-button {
  min-width: 125px;
  color: #fff;
}

@media only screen and (min-width: 901px) {
  :global(.lw-global-theme) .desktop-container {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-pack: center;
        justify-content: center;
  }
  :global(.lw-global-theme) .search-button {
    min-width: 140px;
    color: #fff;
    font-size: 24px;
    background: #fa6401;
    border-radius: 0 3px 3px 0;
    font-family: "Noto Serif", serif;
  }
}
@media only screen and (min-width: 1401px) {
  :global(.lw-global-theme) .search-container {
    width: 68.45%;
  }
}

@media only screen and (min-width: 901px) {
  :global(.loa-global-theme) .search-container {
    padding: 0px;
    width: 100%;
    height: 48px;
    min-height: 48px;
  }
  :global(.loa-global-theme) .range {
    display: none;
  }
  :global(.loa-global-theme) .range-content {
    display: -ms-flexbox;
    display: flex;
  }
  :global(.loa-global-theme) .arrow {
    display: inline-block;
    background: url("/assets/images/shared-bundle.svg#arrow-down") no-repeat center center;
    width: 23px;
    height: 23px;
    background-size: contain;
  }
  :global(.loa-global-theme) .search-button {
    background: #006d31;
    color: #fff;
  }
}
:global(.loa-global-theme) :global(.Home) .search-bar-collapsed {
  border-radius: 50px;
  padding-left: 6px;
}
:global(.loa-global-theme) :global(.Home) .search-bar-collapsed.search-container {
  height: 56px;
  background-color: #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
}
:global(.loa-global-theme) :global(.Home) .search-bar-collapsed.search-container .range {
  display: none;
}
:global(.loa-global-theme) :global(.Home) .search-bar-collapsed.search-container .search-button {
  z-index: 3;
  width: 104px;
  height: 40px;
  border-radius: 50px;
  font-size: 16px;
  position: absolute;
  right: 8px;
}