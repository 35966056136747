:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.show-all-trigger {
  color: #006d31;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.h1 {
  margin: 10px 0 10px 0;
}
@media only screen and (min-width: 601px) {
  .h1 {
    display: inline-block;
    font-size: 27px;
    margin-top: 25px;
    margin-bottom: 0px;
  }
}

.secondary-text {
  margin: 0 0 10px 0;
  display: block;
}
@media only screen and (min-width: 601px) {
  .secondary-text {
    display: inline-block;
    font-size: 12px;
    margin-left: 10px;
  }
}

.arrow {
  display: inline-block;
  background: url("/assets/images/land-bundle.svg#arrow-down-green") no-repeat center center;
  width: 18px;
  height: 18px;
  background-size: contain;
  -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
}