:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

/* Common properties */
.home-ad {
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

:global(.lw-global-theme) .home-ad {
  padding: 0px 0px 10px 0px;
}