:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.img {
  display: block;
  width: 100%;
}

.lazy-container {
  height: 100%;
}