:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.diamond-placard-info-desc {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
@media only screen and (min-width: 601px) {
  .diamond-placard-info-desc {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }
}

.platinum-placard-info-desc,
.showcase-placard-info-desc {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

.base-placard-info-desc {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

.price-change-tag {
  display: none;
}

.placard-info-map,
.placard-info {
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -ms-flex: 1 1;
      flex: 1 1;
  padding: 0 0 4px;
}
.placard-info-map .placard-header,
.placard-info-map .address,
.placard-info-map .summary,
.placard-info .placard-header,
.placard-info .address,
.placard-info .summary {
  margin: 0 0 4px;
}
.placard-info-map .address,
.placard-info-map .summary,
.placard-info .address,
.placard-info .summary {
  line-height: 18px;
  -ms-flex-direction: column;
      flex-direction: column;
}
.placard-info-map .address .address1,
.placard-info-map .summary .address1,
.placard-info .address .address1,
.placard-info .summary .address1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.placard-info-map .address .address1 span,
.placard-info-map .summary .address1 span,
.placard-info .address .address1 span,
.placard-info .summary .address1 span {
  display: none;
}
.placard-info-map .summary-placeholder,
.placard-info .summary-placeholder {
  display: none;
}
.placard-info-map .placard-header,
.placard-info .placard-header {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  text-transform: capitalize;
}
.placard-info-map .acres,
.placard-info-map .price,
.placard-info .acres,
.placard-info .price {
  display: inline-block;
}
.placard-info-map .circle,
.placard-info .circle {
  display: inline;
  margin: 0px 0.05rem;
}
.placard-info-map .placard-detail,
.placard-info .placard-detail {
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 1 1;
      flex: 1 1;
}
.placard-info-map .placard-detail a:hover,
.placard-info .placard-detail a:hover {
  color: #333;
  text-decoration: none;
}
.placard-info-map .desc-map,
.placard-info-map .desc,
.placard-info .desc-map,
.placard-info .desc {
  overflow: hidden;
  -ms-flex: 1 1;
      flex: 1 1;
  display: none;
  -ms-flex-align: center;
      align-items: center;
  line-height: 18px;
}

.diamond-srp-broker-container {
  display: none;
}

:global(.PropertySearch) .price-change-tag {
  display: inline-block;
}
:global(.PropertySearch) .acres {
  margin-right: 4px;
}
:global(.PropertySearch) .level-free .placard-header {
  font-size: 14px;
  margin: 0px;
  display: grid;
  grid-template-columns: minmax(104px, 1fr) 1fr;
}
:global(.PropertySearch) .level-free .placard-header .price-change-tag {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
}
:global(.PropertySearch) .level-free .price,
:global(.PropertySearch) .level-free .acres {
  display: block;
}
:global(.PropertySearch) .level-free .circle {
  display: none;
}
:global(.PropertySearch) .level-free .summary {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
:global(.PropertySearch) .level-free .placard-detail .summary,
:global(.PropertySearch) .level-free .placard-detail .address {
  font-size: 12px;
  line-height: 16px;
  min-height: none;
}
:global(.PropertySearch) .level-free .no-ellipsis .address1 {
  overflow: auto;
  white-space: normal;
  text-overflow: unset;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  line-height: 16px;
}
:global(.PropertySearch) .level-free .no-ellipsis .address1 p::after {
  content: "\a";
  white-space: pre;
}
@media only screen and (min-width: 601px) {
  :global(.PropertySearch) .level-free .placard-header {
    display: block;
  }
  :global(.PropertySearch) .level-free .placard-header .price-change-tag {
    display: inline-block;
  }
}
:global(.PropertySearch) .level-diamond .address .address1 {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  color: #302c2d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.3px;
}
@media only screen and (min-width: 601px) {
  :global(.PropertySearch) .level-diamond .address .address1 {
    display: -ms-flexbox;
    display: flex;
  }
}
:global(.PropertySearch) .level-diamond .address .address1 span {
  display: inline;
}
:global(.PropertySearch) .level-diamond .address .address1 br {
  display: none;
}
:global(.PropertySearch) .level-diamond.placard-info-map, :global(.PropertySearch) .level-diamond.placard-info {
  display: -ms-flexbox;
  display: flex;
  padding: 0 0 8px;
  -ms-flex-direction: column;
      flex-direction: column;
}
:global(.PropertySearch) .level-diamond.placard-info-map .placard-detail, :global(.PropertySearch) .level-diamond.placard-info .placard-detail {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 2 1;
      flex: 2 1;
}
@media only screen and (min-width: 1025px) {
  :global(.PropertySearch) .level-diamond.placard-info-map .placard-detail, :global(.PropertySearch) .level-diamond.placard-info .placard-detail {
    -ms-flex: 3 1;
        flex: 3 1;
  }
}
:global(.PropertySearch) .level-diamond.placard-info-map .placard-detail .summary, :global(.PropertySearch) .level-diamond.placard-info .placard-detail .summary {
  overflow: hidden;
  color: #302c2d;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2px;
}
:global(.PropertySearch) .level-diamond.placard-info-map .placard-detail .desc,
:global(.PropertySearch) .level-diamond.placard-info-map .placard-detail .desc-map, :global(.PropertySearch) .level-diamond.placard-info .placard-detail .desc,
:global(.PropertySearch) .level-diamond.placard-info .placard-detail .desc-map {
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  color: #302c2d;
  -ms-flex-align: baseline;
      align-items: baseline;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.3px;
}
@media only screen and (min-width: 601px) {
  :global(.PropertySearch) .level-diamond.placard-info {
    -ms-flex-direction: row;
        flex-direction: row;
  }
}
@media only screen and (min-width: 1025px) {
  :global(.PropertySearch) .level-diamond.placard-info-map {
    -ms-flex-direction: row;
        flex-direction: row;
  }
}
@media only screen and (min-width: 601px) {
  :global(.PropertySearch) .placard-info-map.level-premium .placard-header, :global(.PropertySearch) .placard-info-map.level-standard .placard-header, :global(.PropertySearch) .placard-info-map.level-signature .placard-header, :global(.PropertySearch) .placard-info-map.level-showcase .placard-header, :global(.PropertySearch) .placard-info-map.level-gold .placard-header, :global(.PropertySearch) .placard-info-map.level-platinum .placard-header,
  :global(.PropertySearch) .placard-info.level-premium .placard-header,
  :global(.PropertySearch) .placard-info.level-standard .placard-header,
  :global(.PropertySearch) .placard-info.level-signature .placard-header,
  :global(.PropertySearch) .placard-info.level-showcase .placard-header,
  :global(.PropertySearch) .placard-info.level-gold .placard-header,
  :global(.PropertySearch) .placard-info.level-platinum .placard-header {
    margin: 0 0 4px;
  }
  :global(.PropertySearch) .placard-info-map.level-premium .summary, :global(.PropertySearch) .placard-info-map.level-standard .summary, :global(.PropertySearch) .placard-info-map.level-signature .summary, :global(.PropertySearch) .placard-info-map.level-showcase .summary, :global(.PropertySearch) .placard-info-map.level-gold .summary, :global(.PropertySearch) .placard-info-map.level-platinum .summary,
  :global(.PropertySearch) .placard-info.level-premium .summary,
  :global(.PropertySearch) .placard-info.level-standard .summary,
  :global(.PropertySearch) .placard-info.level-signature .summary,
  :global(.PropertySearch) .placard-info.level-showcase .summary,
  :global(.PropertySearch) .placard-info.level-gold .summary,
  :global(.PropertySearch) .placard-info.level-platinum .summary {
    line-height: 18px;
    margin: 0 0 4px;
  }
  :global(.PropertySearch) .placard-info-map.level-premium .address, :global(.PropertySearch) .placard-info-map.level-standard .address, :global(.PropertySearch) .placard-info-map.level-signature .address, :global(.PropertySearch) .placard-info-map.level-showcase .address, :global(.PropertySearch) .placard-info-map.level-gold .address, :global(.PropertySearch) .placard-info-map.level-platinum .address,
  :global(.PropertySearch) .placard-info.level-premium .address,
  :global(.PropertySearch) .placard-info.level-standard .address,
  :global(.PropertySearch) .placard-info.level-signature .address,
  :global(.PropertySearch) .placard-info.level-showcase .address,
  :global(.PropertySearch) .placard-info.level-gold .address,
  :global(.PropertySearch) .placard-info.level-platinum .address {
    line-height: 18px;
    margin: 0 0 4px;
  }
  :global(.PropertySearch) .placard-info-map.level-premium .address .address1, :global(.PropertySearch) .placard-info-map.level-standard .address .address1, :global(.PropertySearch) .placard-info-map.level-signature .address .address1, :global(.PropertySearch) .placard-info-map.level-showcase .address .address1, :global(.PropertySearch) .placard-info-map.level-gold .address .address1, :global(.PropertySearch) .placard-info-map.level-platinum .address .address1,
  :global(.PropertySearch) .placard-info.level-premium .address .address1,
  :global(.PropertySearch) .placard-info.level-standard .address .address1,
  :global(.PropertySearch) .placard-info.level-signature .address .address1,
  :global(.PropertySearch) .placard-info.level-showcase .address .address1,
  :global(.PropertySearch) .placard-info.level-gold .address .address1,
  :global(.PropertySearch) .placard-info.level-platinum .address .address1 {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
  :global(.PropertySearch) .placard-info-map.level-premium .address .address1 span, :global(.PropertySearch) .placard-info-map.level-standard .address .address1 span, :global(.PropertySearch) .placard-info-map.level-signature .address .address1 span, :global(.PropertySearch) .placard-info-map.level-showcase .address .address1 span, :global(.PropertySearch) .placard-info-map.level-gold .address .address1 span, :global(.PropertySearch) .placard-info-map.level-platinum .address .address1 span,
  :global(.PropertySearch) .placard-info.level-premium .address .address1 span,
  :global(.PropertySearch) .placard-info.level-standard .address .address1 span,
  :global(.PropertySearch) .placard-info.level-signature .address .address1 span,
  :global(.PropertySearch) .placard-info.level-showcase .address .address1 span,
  :global(.PropertySearch) .placard-info.level-gold .address .address1 span,
  :global(.PropertySearch) .placard-info.level-platinum .address .address1 span {
    display: inline;
  }
  :global(.PropertySearch) .placard-info-map.level-premium .address .address1 br, :global(.PropertySearch) .placard-info-map.level-standard .address .address1 br, :global(.PropertySearch) .placard-info-map.level-signature .address .address1 br, :global(.PropertySearch) .placard-info-map.level-showcase .address .address1 br, :global(.PropertySearch) .placard-info-map.level-gold .address .address1 br, :global(.PropertySearch) .placard-info-map.level-platinum .address .address1 br,
  :global(.PropertySearch) .placard-info.level-premium .address .address1 br,
  :global(.PropertySearch) .placard-info.level-standard .address .address1 br,
  :global(.PropertySearch) .placard-info.level-signature .address .address1 br,
  :global(.PropertySearch) .placard-info.level-showcase .address .address1 br,
  :global(.PropertySearch) .placard-info.level-gold .address .address1 br,
  :global(.PropertySearch) .placard-info.level-platinum .address .address1 br {
    display: none;
  }
  :global(.PropertySearch) .placard-info-map.level-premium .desc-map,
  :global(.PropertySearch) .placard-info-map.level-premium .desc, :global(.PropertySearch) .placard-info-map.level-standard .desc-map,
  :global(.PropertySearch) .placard-info-map.level-standard .desc, :global(.PropertySearch) .placard-info-map.level-signature .desc-map,
  :global(.PropertySearch) .placard-info-map.level-signature .desc, :global(.PropertySearch) .placard-info-map.level-showcase .desc-map,
  :global(.PropertySearch) .placard-info-map.level-showcase .desc, :global(.PropertySearch) .placard-info-map.level-gold .desc-map,
  :global(.PropertySearch) .placard-info-map.level-gold .desc, :global(.PropertySearch) .placard-info-map.level-platinum .desc-map,
  :global(.PropertySearch) .placard-info-map.level-platinum .desc,
  :global(.PropertySearch) .placard-info.level-premium .desc-map,
  :global(.PropertySearch) .placard-info.level-premium .desc,
  :global(.PropertySearch) .placard-info.level-standard .desc-map,
  :global(.PropertySearch) .placard-info.level-standard .desc,
  :global(.PropertySearch) .placard-info.level-signature .desc-map,
  :global(.PropertySearch) .placard-info.level-signature .desc,
  :global(.PropertySearch) .placard-info.level-showcase .desc-map,
  :global(.PropertySearch) .placard-info.level-showcase .desc,
  :global(.PropertySearch) .placard-info.level-gold .desc-map,
  :global(.PropertySearch) .placard-info.level-gold .desc,
  :global(.PropertySearch) .placard-info.level-platinum .desc-map,
  :global(.PropertySearch) .placard-info.level-platinum .desc {
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
  }
  :global(.PropertySearch) .level-free .placard-header {
    font-size: 16px;
    margin: 0 0 4px 0;
  }
  :global(.PropertySearch) .level-free .placard-detail .price,
  :global(.PropertySearch) .level-free .placard-detail .acres,
  :global(.PropertySearch) .level-free .placard-detail .circle {
    display: inline-block;
  }
  :global(.PropertySearch) .level-free .placard-detail .summary,
  :global(.PropertySearch) .level-free .placard-detail .address {
    font-size: 14px;
  }
  :global(.PropertySearch) .level-diamond.placard-info .placard-detail {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
  }
  :global(.PropertySearch) .level-diamond.placard-info .placard-detail .placard-detail-left-column {
    border-right: 1px solid var(--grey-20-light-gray, #cbcbcb);
    min-width: 240px;
    max-width: 280px;
    padding-right: 24px;
  }
  :global(.PropertySearch) .level-diamond.placard-info .placard-detail .summary-placeholder {
    display: block;
  }
  :global(.PropertySearch) .level-diamond.placard-info .placard-detail .summary {
    min-height: 20px;
  }
  :global(.PropertySearch) .level-diamond.placard-info .placard-detail .desc {
    padding-left: 24px;
    -ms-flex-align: center;
        align-items: center;
  }
  :global(.PropertySearch) .level-diamond .diamond-srp-broker-container {
    display: block;
  }
}
@media only screen and (min-width: 990px) and (max-width: 1024px) {
  :global(.PropertySearch) .level-premium.placard-info-map .placard-detail .desc-map,
  :global(.PropertySearch) .level-standard.placard-info-map .placard-detail .desc-map,
  :global(.PropertySearch) .level-signature.placard-info-map .placard-detail .desc-map,
  :global(.PropertySearch) .level-showcase.placard-info-map .placard-detail .desc-map,
  :global(.PropertySearch) .level-gold.placard-info-map .placard-detail .desc-map,
  :global(.PropertySearch) .level-platinum.placard-info-map .placard-detail .desc-map,
  :global(.PropertySearch) .level-free.placard-info-map .placard-detail .desc-map {
    display: none;
  }
  :global(.PropertySearch) .level-diamond.placard-info-map .diamond-srp-broker-container {
    display: none;
  }
}
@media only screen and (min-width: 1025px) {
  :global(.PropertySearch) .level-diamond.placard-info-map {
    padding: 0px;
  }
  :global(.PropertySearch) .level-diamond.placard-info-map .placard-detail {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
  }
  :global(.PropertySearch) .level-diamond.placard-info-map .placard-detail .placard-detail-left-column {
    border-right: 1px solid var(--grey-20-light-gray, #cbcbcb);
    min-width: 240px;
    max-width: 280px;
    padding-right: 24px;
  }
  :global(.PropertySearch) .level-diamond.placard-info-map .placard-detail .summary {
    min-height: 20px;
  }
  :global(.PropertySearch) .level-diamond.placard-info-map .placard-detail .summary-placeholder {
    display: block;
  }
  :global(.PropertySearch) .level-diamond.placard-info-map .placard-detail .diamond-srp-broker-container {
    display: block;
  }
  :global(.PropertySearch) .level-diamond.placard-info-map .placard-detail .desc-map {
    padding-left: 24px;
    -ms-flex-align: center;
        align-items: center;
  }
}

:global(.laf-global-theme) .placard-header-info .desc,
:global(.laf-global-theme) .placard-info .desc {
  font-family: Lora, sans-serif;
  font-style: italic;
}

@media only screen and (min-width: 601px) {
  :global(.lw-global-theme) :global(.PropertySearch) .diamond-placard-info-desc {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }
}
:global(.lw-global-theme) :global(.PropertySearch) .level-diamond.placard-info .placard-detail {
  -ms-flex-direction: column;
      flex-direction: column;
}
:global(.lw-global-theme) :global(.PropertySearch) .level-diamond.placard-info .placard-detail .placard-detail-left-column {
  border-right: none;
  min-width: none;
  max-width: none;
  padding: 0px;
}
:global(.lw-global-theme) :global(.PropertySearch) .level-diamond.placard-info .placard-detail .desc {
  padding-left: 0px;
}
:global(.lw-global-theme) :global(.PropertySearch) .level-diamond.placard-info .placard-detail .summary {
  line-height: 21px;
  letter-spacing: 0;
}
:global(.lw-global-theme) :global(.PropertySearch) .level-diamond.placard-info .placard-detail .address .address1 {
  line-height: 21px;
  letter-spacing: 0;
}
:global(.lw-global-theme) :global(.PropertySearch) .level-diamond.placard-info .placard-detail .desc {
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 21px;
}
:global(.lw-global-theme) .placard-header-info .placard-header a,
:global(.lw-global-theme) .placard-info .placard-header a {
  font-weight: 600;
}

@media only screen and (min-width: 601px) {
  :global(.laf-global-theme) :global(.PropertySearch) .diamond-placard-info-desc {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }
}
:global(.laf-global-theme) :global(.PropertySearch) .level-diamond.placard-info .placard-detail {
  -ms-flex-direction: column;
      flex-direction: column;
}
:global(.laf-global-theme) :global(.PropertySearch) .level-diamond.placard-info .placard-detail .placard-detail-left-column {
  border-right: none;
  min-width: none;
  max-width: none;
  padding: 0px;
}
:global(.laf-global-theme) :global(.PropertySearch) .level-diamond.placard-info .placard-detail .desc {
  padding-left: 0px;
}
:global(.laf-global-theme) :global(.PropertySearch) .level-diamond.placard-info .placard-detail .summary {
  line-height: 21px;
  letter-spacing: 0;
}
:global(.laf-global-theme) :global(.PropertySearch) .level-diamond.placard-info .placard-detail .address .address1 {
  line-height: 21px;
  letter-spacing: 0;
}
:global(.laf-global-theme) :global(.PropertySearch) .level-diamond.placard-info .placard-detail .desc {
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 21px;
}
:global(.laf-global-theme) .placard-header-info .placard-header a,
:global(.laf-global-theme) .placard-info .placard-header a {
  font-weight: 600;
}

.level-free-map-click-card.placard-info-map .placard-detail .desc,
.level-free-map-click-card.placard-info-map .placard-detail .desc-map,
.level-showcase-map-click-card.placard-info-map .placard-detail .desc,
.level-showcase-map-click-card.placard-info-map .placard-detail .desc-map {
  display: none;
}

.level-free-map-click-card .placard-header {
  font-size: 14px;
  margin-bottom: 4px;
}
.level-free-map-click-card .price,
.level-free-map-click-card .acres {
  display: block;
}
.level-free-map-click-card .circle {
  display: none;
}
.level-free-map-click-card .summary {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.level-free-map-click-card .placard-detail .summary,
.level-free-map-click-card .placard-detail .address {
  font-size: 12px;
  line-height: 16px;
  min-height: none;
}
.level-free-map-click-card .no-ellipsis .address1 {
  overflow: auto;
  white-space: normal;
  text-overflow: unset;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  line-height: 16px;
}
.level-free-map-click-card .no-ellipsis .address1 p::after {
  content: "\a";
  white-space: pre;
}

.level-showcase-map-click-card .placard-detail .summary,
.level-showcase-map-click-card .placard-detail .address {
  line-height: 20px;
}