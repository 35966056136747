:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.action-buttons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  gap: 16px;
  margin-top: 8px;
}
.action-buttons .contact-button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 1 1;
      flex: 1 1;
  text-decoration: none;
  border-radius: 6px;
  height: 40px;
  border: 1px solid #302c2d;
}
.action-buttons .contact-button:hover {
  background: rgba(203, 203, 203, 0.2);
}
.action-buttons .contact-button .contact-button-text {
  font-family: Roboto, "Franklin Gothic Medium", Tahoma, sans-serif;
  color: #302c2d;
  font-weight: 500;
}
@media only screen and (min-width: 601px) {
  .action-buttons {
    display: none;
  }
}