:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

:global(.loa-global-theme) .tag {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 2px 5px 2px 10px;
  margin: 2px 8px 2px 0;
  position: relative;
  line-height: 22px;
  background-color: #eef1f3;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  overflow: hidden;
  min-width: 50px;
  font-size: 12px;
  color: #444444;
}
:global(.loa-global-theme) .tag .tag-no-x {
  padding: 2px 10px 2px 10px;
}
:global(.loa-global-theme) .tag .remove {
  cursor: pointer;
  margin-left: 7px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  width: 25px;
  height: 18px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
:global(.loa-global-theme) .tag .remove .x {
  margin-bottom: 2px;
  display: inline-block;
  background: url("/assets/images/close.svg") no-repeat center center;
  width: 16px;
  height: 16px;
  background-size: contain;
  opacity: 0.5;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
:global(.loa-global-theme) .tag .children {
  width: 100%;
  overflow: hidden;
}
@media only screen and (min-width: 601px) {
  :global(.loa-global-theme) .tag {
    border: 1px solid #cccccc;
    border-radius: 20px;
    line-height: 24px;
    background-color: #eeeeee;
    height: 20px;
  }
  :global(.loa-global-theme) .tag.is-focused, :global(.loa-global-theme) .tag:hover {
    -webkit-filter: brightness(70%);
            filter: brightness(70%);
  }
  :global(.loa-global-theme) .tag .remove {
    border: 1px solid #fff;
    border-radius: 30px;
    width: 18px;
  }
  :global(.loa-global-theme) .tag .remove .x {
    margin-bottom: 3px;
  }
}
:global(.loa-global-theme) :global(.Home) .tag {
  border-radius: 25px;
}
:global(.loa-global-theme) :global(.Broker-Directory-New) .tag {
  margin: none;
  display: -ms-flexbox;
  display: flex;
  gap: 8px;
  border-radius: 20px;
  border: 1px solid #cbcbcb;
  background: #f1f3f4;
  color: #302c2d;
  font-family: Roboto, "Franklin Gothic Medium", Tahoma, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2px;
  max-width: 150px;
}
:global(.loa-global-theme) :global(.Broker-Directory-New) .tag .children {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
:global(.loa-global-theme) :global(.Broker-Directory-New) .tag .remove {
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  background: transparent;
  border: none;
  opacity: none;
  margin: 0px;
  position: relative;
  top: 1px;
}
:global(.loa-global-theme) :global(.Broker-Directory-New) .tag .remove .x {
  opacity: 1;
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("/assets/images/close-seller-directory-tag.svg") no-repeat center center;
  width: 20px;
  height: 20px;
  background-size: contain;
}
@media only screen and (min-width: 601px) {
  :global(.loa-global-theme) :global(.Broker-Directory-New) .tag {
    max-width: none;
  }
}

:global(.lw-global-theme) .tag {
  border: 1px solid #cccccc;
  border-radius: 20px;
  padding: 1px 5px 2px 8px;
  margin: 4px 8px 2px 0;
  position: relative;
  line-height: 24px;
  background-color: #eeeeee;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  overflow: hidden;
  min-width: 50px;
}
@media only screen and (max-width: 900.9px) {
  :global(.lw-global-theme) .tag {
    padding-top: 2px;
  }
}
:global(.lw-global-theme) .tag.is-focused, :global(.lw-global-theme) .tag:hover {
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
}
:global(.lw-global-theme) .tag .tag-no-x {
  padding: 2px 10px 2px 10px;
}
:global(.lw-global-theme) .tag .remove {
  cursor: pointer;
  margin-left: 9px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 30px;
  width: 20px;
  height: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
:global(.lw-global-theme) .tag .remove .x {
  margin-bottom: 3px;
  display: inline-block;
  background: url("/assets/images/close.svg") no-repeat center center;
  width: 16px;
  height: 16px;
  background-size: contain;
  opacity: 0.6;
}
:global(.lw-global-theme) .tag .children {
  width: 100%;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 900.9px) {
  :global(.lw-global-theme) .tag .children {
    font-size: 14px;
  }
}
@media only screen and (max-width: 600.9px) {
  :global(.lw-global-theme) .tag .children {
    font-size: 10px;
  }
}
@media only screen and (max-width: 374.9px) {
  :global(.lw-global-theme) .tag .children {
    font-size: 10px;
  }
}
:global(.lw-global-theme) :global(.Broker-Directory-New) .tag {
  display: -ms-flexbox;
  display: flex;
  padding: 4px 4px 4px 8px;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: center;
      align-items: center;
  gap: 8px;
  height: 28px;
  border-radius: 20px;
  border: 1px solid #ddd;
  background: #ddd;
  margin: 4px 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 150px;
}
:global(.lw-global-theme) :global(.Broker-Directory-New) .tag .children {
  color: #333;
  font-family: "Open Sans", "Segoe UI", Tahoma, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
:global(.lw-global-theme) :global(.Broker-Directory-New) .tag .remove {
  background: none;
  border: none;
  margin-left: 0px;
}
:global(.lw-global-theme) :global(.Broker-Directory-New) .tag .x {
  margin: 0px;
  opacity: 1;
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("/assets/images/close-seller-directory-tag.svg") no-repeat center center;
  width: 20px;
  height: 20px;
  background-size: contain;
}
@media only screen and (min-width: 601px) {
  :global(.lw-global-theme) :global(.Broker-Directory-New) .tag {
    max-width: none;
  }
}

:global(.laf-global-theme) .tag {
  border: 1px solid #cccccc;
  border-radius: 20px;
  padding: 1px 5px 2px 8px;
  margin: 4px 8px 2px 0;
  position: relative;
  line-height: 24px;
  background-color: #eeeeee;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  overflow: hidden;
  min-width: 50px;
}
@media only screen and (max-width: 900.9px) {
  :global(.laf-global-theme) .tag {
    padding-top: 2px;
  }
}
:global(.laf-global-theme) .tag.is-focused, :global(.laf-global-theme) .tag:hover {
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
}
:global(.laf-global-theme) .tag .tag-no-x {
  padding: 2px 10px 2px 10px;
}
:global(.laf-global-theme) .tag .remove {
  cursor: pointer;
  margin-left: 9px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 30px;
  width: 20px;
  height: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
:global(.laf-global-theme) .tag .remove .x {
  margin-bottom: 3px;
  display: inline-block;
  background: url("/assets/images/close.svg") no-repeat center center;
  width: 16px;
  height: 16px;
  background-size: contain;
  opacity: 0.6;
}
:global(.laf-global-theme) .tag .children {
  width: 100%;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 900.9px) {
  :global(.laf-global-theme) .tag .children {
    font-size: 14px;
  }
}
@media only screen and (max-width: 600.9px) {
  :global(.laf-global-theme) .tag .children {
    font-size: 10px;
  }
}
@media only screen and (max-width: 374.9px) {
  :global(.laf-global-theme) .tag .children {
    font-size: 10px;
  }
}
:global(.laf-global-theme) :global(.Broker-Directory-New) .tag {
  display: -ms-flexbox;
  display: flex;
  padding: 4px 4px 4px 8px;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: center;
      align-items: center;
  gap: 8px;
  height: 28px;
  border-radius: 20px;
  border: 1px solid #ddd;
  background: #ddd;
  margin: 4px 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 150px;
}
:global(.laf-global-theme) :global(.Broker-Directory-New) .tag .children {
  color: #333;
  font-family: "Open Sans", "Segoe UI", Tahoma, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
:global(.laf-global-theme) :global(.Broker-Directory-New) .tag .remove {
  background: none;
  border: none;
  margin-left: 0px;
}
:global(.laf-global-theme) :global(.Broker-Directory-New) .tag .x {
  margin: 0px;
  opacity: 1;
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("/assets/images/close-seller-directory-tag.svg") no-repeat center center;
  width: 20px;
  height: 20px;
  background-size: contain;
}
@media only screen and (min-width: 601px) {
  :global(.laf-global-theme) :global(.Broker-Directory-New) .tag {
    max-width: none;
  }
}

:global(.laf-global-theme) :global(.Broker-Directory-New) .tag {
  background-color: #fff;
}