:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.placard .contact-button {
  padding: 0px;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 35px;
  width: 116px;
  margin-right: 5px;
  font-size: 16px;
}
@media only screen and (min-width: 901px) {
  .placard .contact-button.placard-contact {
    width: 126px;
  }
}

:global(.lw-global-theme) .contact-seller {
  display: none;
}
@media only screen and (min-width: 901px) {
  :global(.lw-global-theme) .contact-seller {
    display: block;
  }
}

:global(.laf-global-theme) .contact-button {
  font-weight: 600;
}
:global(.laf-global-theme) .contact-seller {
  display: none;
}