:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.blurb-container {
  display: -ms-flexbox;
  display: flex;
  height: 216px;
  margin-bottom: 16px;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  gap: 24px;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  border-radius: 6px;
}
@media only screen and (min-width: 1401px) {
  .blurb-container {
    max-width: 24.1666667%;
    -ms-flex: 1 1;
        flex: 1 1;
  }
}

.pagination-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

.near-me-carousel-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
@media only screen and (min-width: 1401px) {
  .near-me-carousel-container {
    -ms-flex-direction: row;
        flex-direction: row;
    gap: 16px;
  }
}

.title {
  color: #0e0d0c;
  font-family: Athelas, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
}

.body {
  color: #302c2d;
  text-align: center;
  font-family: Roboto, "Franklin Gothic Medium", Tahoma, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  max-width: 480px;
}

.button {
  width: 228px;
  height: 48px;
}

@media only screen and (min-width: 601px) {
  .blurb-container {
    height: 224px;
  }
  .title {
    font-size: 32px;
    font-weight: 400;
  }
}
@media only screen and (min-width: 1401px) {
  .blurb-container {
    height: 320px;
    padding: 0px;
    -ms-flex-align: start;
        align-items: flex-start;
  }
  .title {
    color: #0e0d0c;
    font-family: Athelas, serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    text-align: left;
  }
  .body {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
  .button {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
  }
  .button-container {
    text-align: left;
    width: 100%;
  }
}