:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

/* Common properties */
.hero-section {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
}

.marketing-container {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  gap: 40px;
  background-color: #253732;
  justify-items: center;
  padding: 40px 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-flex-align: center;
      align-items: center;
}
@media only screen and (min-width: 601px) {
  .marketing-container {
    padding: 40px 24px;
  }
}
@media only screen and (min-width: 1401px) {
  .marketing-container {
    padding: 48px 24px;
  }
}

.marketing-section {
  max-width: 1540px;
  width: 100%;
}

.marketing-blurbs {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}
@media only screen and (min-width: 1025px) {
  .marketing-blurbs {
    -ms-flex-direction: row;
        flex-direction: row;
    margin-bottom: 24px;
    gap: 16px;
  }
}

.section,
.showcase-section {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
@media only screen and (min-width: 601px) {
  .section,
  .showcase-section {
    max-width: calc(1601px + 60px);
    margin-top: 0;
    padding: 0 30px;
  }
}

@media only screen and (min-width: 375px) {
  .view-results-button {
    width: 184px;
    height: 50px;
  }
}
@media only screen and (min-width: 990px) {
  .view-results-button {
    height: 48px;
  }
}
@media only screen and (min-width: 1601px) {
  .view-results-button {
    width: 536px;
  }
}

.home-link-section {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  padding: 24px;
  background-color: #f6f8f8;
}
@media only screen and (min-width: 601px) {
  .home-link-section {
    padding: 40px 24px;
  }
}

.main {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-direction: column;
      flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 100vh;
}
.main:focus {
  outline: 0;
}

.favorites-container,
.popular-land-container,
.activities-container {
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -ms-flex-direction: column;
      flex-direction: column;
}
@media only screen and (min-width: 1401px) {
  .favorites-container,
  .popular-land-container,
  .activities-container {
    -ms-flex-direction: row;
        flex-direction: row;
  }
}

:global(.Home) .section,
:global(.Home) .showcase-section {
  padding: 0px 16px;
}
@media only screen and (min-width: 990px) {
  :global(.Home) .section,
  :global(.Home) .showcase-section {
    max-width: 960px;
  }
}
@media only screen and (min-width: 1201px) {
  :global(.Home) .section,
  :global(.Home) .showcase-section {
    max-width: 1156px;
    padding: 0px;
  }
}
@media only screen and (min-width: 1401px) {
  :global(.Home) .section,
  :global(.Home) .showcase-section {
    max-width: 1280px;
  }
}
@media only screen and (min-width: 1601px) {
  :global(.Home) .section,
  :global(.Home) .showcase-section {
    max-width: 1366px;
  }
}
@media only screen and (min-width: 1800px) {
  :global(.Home) .section,
  :global(.Home) .showcase-section {
    max-width: 1440px;
  }
}
:global(.Home) .section:last-of-type {
  margin-bottom: 24px;
}
:global(.Home) .showcase-section {
  min-height: 821px;
}
@media only screen and (min-width: 901px) {
  :global(.Home) .showcase-section {
    min-height: 821px;
  }
}
@media only screen and (min-width: 1025px) {
  :global(.Home) .showcase-section {
    min-height: 879px;
  }
}
@media only screen and (min-width: 1201px) {
  :global(.Home) .showcase-section {
    min-height: 407px;
  }
}
@media only screen and (min-width: 1401px) {
  :global(.Home) .showcase-section {
    min-height: 426px;
  }
}
@media only screen and (min-width: 1601px) {
  :global(.Home) .showcase-section {
    min-height: 440px;
  }
}