:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.embla {
  overflow: hidden;
  position: relative;
  height: 100%;
  --slide-height: 100%;
}

/* This resolves the issue on 375-400 px where the Platinum Placard Carousel image doesn't fill the container.
  Therefore, leaving extra white space under the image */
.level-diamondMobile-map,
.level-diamondDesktop-map,
.level-diamondDesktop,
.level-diamondMobile,
.level-diamondTablet {
  height: 304px;
  aspect-ratio: auto;
}
.level-diamondMobile-map .embla__viewport,
.level-diamondMobile-map .embla__container img,
.level-diamondDesktop-map .embla__viewport,
.level-diamondDesktop-map .embla__container img,
.level-diamondDesktop .embla__viewport,
.level-diamondDesktop .embla__container img,
.level-diamondMobile .embla__viewport,
.level-diamondMobile .embla__container img,
.level-diamondTablet .embla__viewport,
.level-diamondTablet .embla__container img {
  height: 304px;
  aspect-ratio: auto;
}

.level-platinum-map,
.level-platinum {
  height: 256px;
  aspect-ratio: auto;
}
.level-platinum-map .embla__viewport,
.level-platinum-map .embla__container img,
.level-platinum .embla__viewport,
.level-platinum .embla__container img {
  height: 256px;
  aspect-ratio: auto;
}

.level-gold-map,
.level-gold {
  height: 216px;
  max-height: 216px;
  aspect-ratio: auto;
}
.level-gold-map .embla__viewport,
.level-gold-map .embla__container,
.level-gold-map img,
.level-gold .embla__viewport,
.level-gold .embla__container,
.level-gold img {
  height: 216px;
}

@media only screen and (min-width: 601px) {
  .level-diamondMobile-map,
  .level-diamondDesktop-map,
  .level-diamondMobile,
  .level-diamondTablet,
  .level-diamondDesktop {
    height: 360px;
  }
  .level-diamondMobile-map .embla__viewport,
  .level-diamondMobile-map .embla__container img,
  .level-diamondDesktop-map .embla__viewport,
  .level-diamondDesktop-map .embla__container img,
  .level-diamondMobile .embla__viewport,
  .level-diamondMobile .embla__container img,
  .level-diamondTablet .embla__viewport,
  .level-diamondTablet .embla__container img,
  .level-diamondDesktop .embla__viewport,
  .level-diamondDesktop .embla__container img {
    height: 360px;
  }
  .level-platinum-map,
  .level-platinum {
    height: 256px;
    max-height: 256px;
    aspect-ratio: 1.4375;
  }
  .level-platinum-map .embla__viewport,
  .level-platinum-map .embla__container img,
  .level-platinum .embla__viewport,
  .level-platinum .embla__container img {
    aspect-ratio: 1.4375;
  }
  .level-gold-map,
  .level-gold {
    height: 216px;
    max-height: 216px;
    aspect-ratio: 1.4814814815;
  }
  .level-gold-map .embla__viewport,
  .level-gold-map .embla__container,
  .level-gold-map img,
  .level-gold .embla__viewport,
  .level-gold .embla__container,
  .level-gold img {
    height: 216px;
  }
}
@media only screen and (min-width: 990px) and (max-width: 1024px) {
  .level-diamondMobile-map,
  .level-diamondDesktop-map,
  .level-platinum-map,
  .level-gold-map {
    aspect-ratio: auto;
  }
  .level-diamondMobile-map .embla__viewport,
  .level-diamondMobile-map .embla__container img,
  .level-diamondDesktop-map .embla__viewport,
  .level-diamondDesktop-map .embla__container img,
  .level-platinum-map .embla__viewport,
  .level-platinum-map .embla__container img,
  .level-gold-map .embla__viewport,
  .level-gold-map .embla__container img {
    aspect-ratio: auto;
  }
  .level-diamondMobile-map,
  .level-diamondDesktop-map {
    height: 304px;
  }
}
@media only screen and (min-width: 1025px) {
  .level-diamondMobile-map,
  .level-diamondTablet-map,
  .level-diamondDesktop-map {
    height: 360px;
  }
  .level-diamondMobile-map .embla__viewport,
  .level-diamondMobile-map .embla__container img,
  .level-diamondTablet-map .embla__viewport,
  .level-diamondTablet-map .embla__container img,
  .level-diamondDesktop-map .embla__viewport,
  .level-diamondDesktop-map .embla__container img {
    height: 360px;
  }
}
.embla__viewport {
  overflow: hidden;
  height: 100%;
}

.embla__container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  height: 100%;
}

.embla__prev,
.embla__next,
.icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.embla__prev,
.embla__next {
  z-index: 5;
  border: none;
  width: 40px;
  height: 40px;
}

.icon {
  opacity: 1;
  z-index: 6;
}

.prev-icon {
  left: 15px;
  display: inline-block;
  background: url("/assets/images/shared-bundle.svg#embla-arrow") no-repeat center center;
  width: 10px;
  height: 18px;
  background-size: contain;
}

.next-icon {
  right: 15px;
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
  display: inline-block;
  background: url("/assets/images/shared-bundle.svg#embla-arrow") no-repeat center center;
  width: 10px;
  height: 18px;
  background-size: contain;
}

.embla__slide__number {
  display: none;
}

.mobile .embla__next,
.desktop .embla__next {
  border-radius: 3px 0px 0px 3px;
  right: 0;
}
.mobile .embla__prev,
.desktop .embla__prev {
  border-radius: 0px 3px 3px 0px;
  left: 0;
}
.mobile .embla__prev,
.mobile .embla__next,
.desktop .embla__prev,
.desktop .embla__next {
  background: rgba(14, 13, 12, 0.6);
}
.mobile .embla__prev .icon,
.mobile .embla__next .icon,
.desktop .embla__prev .icon,
.desktop .embla__next .icon {
  opacity: 1;
}
.mobile .embla__slide__number,
.desktop .embla__slide__number {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  gap: 10px;
  padding: 0px 9px;
  height: 22px;
  background: #fff;
  border-radius: 6px;
  -ms-flex: none;
      flex: none;
  -ms-flex-order: 1;
      order: 1;
  -ms-flex-positive: 0;
      flex-grow: 0;
  position: absolute;
  bottom: 13px;
  right: 16px;
  z-index: 5;
  font-size: 12px;
  color: #0e0d0c;
}

.mobile .embla__prev,
.mobile .embla__next,
.mobile .embla__slide__number {
  opacity: 1;
}

.desktop .embla__prev,
.desktop .embla__next,
.desktop .embla__slide__number {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.desktop.hover .embla__prev,
.desktop.hover .embla__next,
.desktop.hover .embla__slide__number {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}