:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.map-icon,
.video-icon,
.tour-icon {
  cursor: pointer;
}
.map-icon[aria-label]:hover:before, .map-icon[aria-label]:hover:after,
.video-icon[aria-label]:hover:before,
.video-icon[aria-label]:hover:after,
.tour-icon[aria-label]:hover:before,
.tour-icon[aria-label]:hover:after {
  display: none;
}

:global(.loa-global-theme) .map-icon {
  display: inline-block;
  background: url("/assets/images/land-bundle.svg#map-view-white") no-repeat center center;
  width: 20px;
  height: 20px;
  background-size: contain;
  margin: 0 3px 0 3px;
}
:global(.loa-global-theme) .video-icon {
  display: inline-block;
  background: url("/assets/images/land-bundle.svg#video-white") no-repeat center center;
  width: 20px;
  height: 20px;
  background-size: contain;
  margin: 0 3px 0 7px;
}
:global(.loa-global-theme) .tour-icon {
  display: inline-block;
  background: url("/assets/images/land-bundle.svg#3d-tours") no-repeat center center;
  width: 20px;
  height: 20px;
  background-size: contain;
  margin: 0 5px 0 5px;
}
:global(.loa-global-theme) .map-icon,
:global(.loa-global-theme) .video-icon,
:global(.loa-global-theme) .tour-icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

:global(.lw-global-theme) .map-icon,
:global(.lw-global-theme) .video-icon,
:global(.lw-global-theme) .tour-icon {
  border-radius: 15px;
  border: 2px solid #fff;
  font-weight: bold;
}

:global(.laf-global-theme) .map-icon,
:global(.laf-global-theme) .video-icon,
:global(.laf-global-theme) .tour-icon {
  border-radius: 0px;
  border: 1px solid #fff;
  font-family: "Open Sans", "Segoe UI", Tahoma, sans-serif;
}

:global(.lw-global-theme) .map-icon,
:global(.lw-global-theme) .video-icon,
:global(.lw-global-theme) .tour-icon {
  width: 55px;
  height: 25px;
  margin-right: 5px;
  font-size: 12px;
  color: #fff;
  text-shadow: 0 0 4px #000;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
:global(.lw-global-theme) .tour-icon {
  width: 80px;
  margin-right: 0px;
  right: 10px;
}

:global(.laf-global-theme) .map-icon,
:global(.laf-global-theme) .video-icon,
:global(.laf-global-theme) .tour-icon {
  width: 55px;
  height: 25px;
  margin-right: 5px;
  font-size: 12px;
  color: #fff;
  text-shadow: 0 0 4px #000;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
:global(.laf-global-theme) .tour-icon {
  width: 80px;
  margin-right: 0px;
  right: 10px;
}