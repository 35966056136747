:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

@media only screen and (min-width: 601px) {
  .overview {
    display: -ms-flexbox;
    display: flex;
    margin-top: 10px;
  }
}

.seo {
  min-height: 150px;
  -ms-flex: 4 1;
      flex: 4 1;
  font-size: 16px;
  line-height: 24px;
}

.stats {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
@media only screen and (min-width: 901px) {
  .stats {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.header {
  margin: 10px 0 10px 0;
}
@media only screen and (min-width: 601px) {
  .header {
    display: inline-block;
    font-size: 27px;
    margin-top: 25px;
    margin-bottom: 0px;
  }
}