:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.header {
  z-index: 2;
  position: relative;
  color: #fff;
}

.sbs-span {
  display: none;
}
@media only screen and (min-width: 901px) {
  .sbs-span {
    display: inline-block;
  }
}

/* Land.com properties */
:global(.loa-global-theme) .h2 {
  display: block;
  padding-top: 20px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  padding-bottom: 20px;
  text-shadow: 1px 1px 1px rgb(0, 0, 0);
  margin: 0px;
}
:global(.loa-global-theme) .main {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 300px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  max-width: calc(1601px + 60px);
  width: 100%;
}
@media only screen and (min-width: 901px) {
  :global(.loa-global-theme) .main {
    height: 400px;
  }
}
:global(.loa-global-theme) .main-header {
  font-size: 37px;
  line-height: 42px;
  margin: 0px;
  text-shadow: 1px 1px 1px rgb(0, 0, 0);
}
:global(.loa-global-theme) .hero {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 300px;
}
@media only screen and (min-width: 901px) {
  :global(.loa-global-theme) .hero {
    height: 400px;
  }
}
@media only screen and (min-width: 1401px) {
  :global(.loa-global-theme) .hero {
    padding: 0 30px;
  }
}
:global(.loa-global-theme) .search--placeholder {
  border-radius: 50px;
}
@media only screen and (min-width: 901px) {
  :global(.loa-global-theme) .search--placeholder {
    position: relative;
    min-height: 48px;
    width: 100%;
    background: #fff;
  }
}
@media only screen and (min-width: 990px) {
  :global(.loa-global-theme) .search--placeholder {
    min-width: 775px;
  }
}
:global(.loa-global-theme) .opaque-background {
  background-color: rgba(51, 51, 51, 0.85);
  z-index: 1;
  padding: 20px;
  height: 100%;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-flex-pack: center;
      justify-content: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
@media only screen and (min-width: 601px) {
  :global(.loa-global-theme) .opaque-background {
    height: auto;
    width: auto;
    padding: 45px 40px;
    max-width: 856px;
    border-radius: 3px;
  }
}
@media only screen and (min-width: 901px) {
  :global(.loa-global-theme) .opaque-background {
    min-height: 239px;
    min-width: 778px;
    padding: 40px 40px;
  }
}
:global(.loa-global-theme) .search-by-state {
  color: white;
  -ms-flex-pack: center;
      justify-content: center;
  opacity: 1;
  overflow: visible;
  z-index: 1;
  display: none;
  width: 30%;
  -ms-flex-item-align: center;
      align-self: center;
}
@media only screen and (min-width: 901px) {
  :global(.loa-global-theme) .search-by-state {
    display: block;
  }
}
:global(.loa-global-theme) .search-by-state:hover {
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
}
:global(.loa-global-theme) .search-by-state span::before {
  content: "";
  position: relative;
  top: 15px;
  right: 10px;
  display: none;
  display: inline-block;
  background: url("/assets/images/map-usa-icon.svg") no-repeat center center;
  width: 45px;
  height: 45px;
  background-size: contain;
}
@media only screen and (min-width: 901px) {
  :global(.loa-global-theme) .search-by-state span::before {
    display: inline-block;
  }
}

/* LandWatch properties */
:global(.lw-global-theme) .h2 {
  display: block;
  padding-top: 20px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  padding-bottom: 20px;
  text-shadow: 1px 1px 1px rgb(0, 0, 0);
  margin: 0px;
}
:global(.lw-global-theme) .main {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  max-width: calc(1601px + 60px);
  width: 100%;
}
:global(.lw-global-theme) .search-by-state {
  font-weight: normal;
  -ms-flex-pack: center;
      justify-content: center;
  opacity: 1;
  overflow: visible;
  z-index: 1;
  display: none;
  width: 192px;
  height: 40px;
  font-family: "Noto Serif", serif;
  -ms-flex-item-align: center;
      align-self: center;
  margin: auto;
  margin-top: 10px;
  font-size: 20px;
  font-weight: normal;
  border-radius: 20px;
  -webkit-filter: none;
  filter: none;
  color: #333333;
  border: 2px solid #333333;
  font-weight: 500;
  background: none;
}
:global(.lw-global-theme) .search-by-state:hover {
  background-color: #333333;
  color: white;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}
@media only screen and (min-width: 901px) {
  :global(.lw-global-theme) .search-by-state {
    display: block;
    margin-bottom: 50px;
  }
}
:global(.lw-global-theme) .opaque-background {
  height: auto;
  width: 85%;
  border-radius: 6px;
}
:global(.lw-global-theme) .main-header {
  height: 38px;
  font-size: 40px;
  color: #000;
  text-align: center;
  margin: 50px 0px 25px 0px;
  font-family: "Noto Serif", serif;
  font-weight: normal;
}
@media only screen and (max-width: 600.9px) {
  :global(.lw-global-theme) .main-header {
    font-size: 28px;
  }
}
@media only screen and (min-width: 601px) {
  :global(.lw-global-theme) .main-header {
    height: 55px;
  }
}
:global(.lw-global-theme) .search--placeholder {
  height: 85px;
}
@media only screen and (min-width: 901px) {
  :global(.lw-global-theme) .search--placeholder {
    position: relative;
    min-height: 48px;
    width: 100%;
    border-radius: 4px;
  }
}
@media only screen and (min-width: 990px) {
  :global(.lw-global-theme) .search--placeholder {
    min-width: 775px;
  }
}
@media only screen and (max-width: 900.9px) {
  :global(.lw-global-theme) .search--placeholder {
    font-size: 28px;
    margin-bottom: 30px;
  }
}

/* LandAndFarm properties */
:global(.laf-global-theme) .main {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  max-width: calc(1601px + 60px);
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
:global(.laf-global-theme) .opaque-background {
  height: auto;
  width: 90%;
  border-radius: 6px;
}
:global(.laf-global-theme) .main-header {
  width: 100%;
  line-height: 33px;
  color: #000;
  text-align: center;
  margin: 20px 0px 0px 0px;
  font-size: 28px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
}
@media only screen and (min-width: 601px) {
  :global(.laf-global-theme) .main-header {
    font-size: 35px;
    margin: 40px auto 0px auto;
  }
}
@media only screen and (min-width: 901px) {
  :global(.laf-global-theme) .main-header {
    font-size: 55px;
    line-height: 62px;
    max-width: 700px;
  }
}
:global(.laf-global-theme) .h2 {
  width: 100%;
  padding-top: 20px;
  line-height: 20px;
  color: #000;
  text-align: center;
  margin: 0px 0px 28px 0px;
  font-size: 14px;
  font-family: Lora, sans-serif;
  font-weight: normal;
  display: block;
}
@media only screen and (min-width: 601px) {
  :global(.laf-global-theme) .h2 {
    font-size: 24px;
    line-height: 33px;
    font-weight: 400;
    text-align: center;
    margin: auto;
    max-width: 400px;
  }
}
@media only screen and (min-width: 901px) {
  :global(.laf-global-theme) .h2 {
    max-width: 650px;
    padding-bottom: 20px;
  }
}
:global(.laf-global-theme) .search--placeholder {
  height: 85px;
  font-size: 28px;
  margin-bottom: 0px;
}
@media only screen and (min-width: 601px) {
  :global(.laf-global-theme) .search--placeholder {
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 901px) {
  :global(.laf-global-theme) .search--placeholder {
    position: relative;
    min-height: 48px;
    width: 100%;
    border-radius: 4px;
  }
}
@media only screen and (min-width: 990px) {
  :global(.laf-global-theme) .search--placeholder {
    min-width: 775px;
  }
}