:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.container {
  max-width: 1600px;
  margin: auto;
  min-height: 1035.8px;
}
@media only screen and (min-width: 601px) {
  .container {
    min-height: 587.8px;
  }
}
@media only screen and (min-width: 1025px) {
  .container {
    min-height: 475.8px;
  }
}
@media only screen and (min-width: 1401px) {
  .container {
    min-height: 363.8px;
  }
}

.title {
  color: #302c2d;
  font-family: Athelas;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

.tab-header-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  padding: 16px 0;
  margin-bottom: 16px;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  height: 40px;
  padding-bottom: 1px;
  border-bottom: 1px solid #cbcbcb;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.tab-header-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.tab-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  height: 64px;
  border-top: 1px solid #eef1f3;
  padding: 24px 16px;
  cursor: pointer;
  display: inline-block;
  -ms-flex-pack: center;
      justify-content: center;
  align-items: center;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  height: 40px;
  border-top: none;
  padding: 8px 12px;
  white-space: nowrap;
  color: #302c2d;
  /* Body/Body Light */
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.3px;
}
.tab-header:hover {
  color: #0e0d0c;
}

.tab-header:first-of-type {
  padding-left: 0;
}

.tab-header-active {
  cursor: default;
  font-weight: 500;
  letter-spacing: 0.4px;
}

.tab-header-active::after {
  content: "";
  display: block;
  position: relative;
  top: 10px;
  width: 100%;
  height: 3px;
  background-color: #008099;
  border-radius: 1px 1px 0px 0px;
}

.tab-pane-container {
  display: inherit;
}

.tab-pane {
  display: none;
  grid-template-columns: repeat(1, 1fr);
}
@media only screen and (min-width: 601px) {
  .tab-pane {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (min-width: 1025px) {
  .tab-pane {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (min-width: 1401px) {
  .tab-pane {
    grid-template-columns: repeat(4, 1fr);
  }
}
.tab-pane li {
  grid-column-start: 1;
}

.tab-pane-active {
  display: grid;
}

.tab-pane-link-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin-right: 0px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 601px) {
  .tab-pane-link-container {
    margin-right: 40px;
  }
}

.tab-pane-link {
  color: #302c2d;
  font-family: Roboto;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.3px;
}

.tab-pane-link-text {
  color: #757575;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2px;
}

.tab-pane-active .show-more-button {
  color: #0e7080;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.3px;
}
.tab-pane-active .show-more-arrow {
  display: inline-block;
  background: url("/assets/images/shared-bundle.svg#arrow-down") no-repeat center center;
  width: 24px;
  height: 24px;
  background-size: contain;
  -webkit-filter: invert(32%) sepia(69%) saturate(563%) hue-rotate(141deg) brightness(95%) contrast(92%);
          filter: invert(32%) sepia(69%) saturate(563%) hue-rotate(141deg) brightness(95%) contrast(92%);
}