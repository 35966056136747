:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.header {
  font-size: 24px;
  margin: 10px 0 24px 0px;
}
@media only screen and (min-width: 601px) {
  .header {
    margin-top: 25px;
  }
}

.listing-card-ad {
  min-height: 370px;
}
@media only screen and (min-width: 601px) {
  .listing-card-ad {
    min-height: 483px;
  }
}
@media only screen and (min-width: 901px) {
  .listing-card-ad {
    min-height: 0px;
  }
}

.header-underline {
  display: none;
}

.wrapper--showcase {
  display: inline-block;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  vertical-align: top;
}

.inner-section {
  margin-left: -10px;
  margin-right: -10px;
}

:global(.loa-global-theme) .header {
  font-family: Athelas, serif;
  text-align: center;
}
:global(.loa-global-theme) .inner-section {
  padding: 0px;
  width: 100%;
  margin: 0px auto 16px auto;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
@media only screen and (min-width: 601px) {
  :global(.loa-global-theme) .inner-section {
    max-width: 552px;
  }
}
@media only screen and (min-width: 901px) {
  :global(.loa-global-theme) .inner-section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    max-width: 708px;
  }
}
@media only screen and (min-width: 990px) {
  :global(.loa-global-theme) .inner-section {
    max-width: 800px;
  }
}
@media only screen and (min-width: 1201px) {
  :global(.loa-global-theme) .inner-section {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    max-width: none;
    width: 100%;
  }
}
:global(.loa-global-theme) .inner-section {
  margin-bottom: 16px;
}
@media only screen and (max-width: -0.1px) {
  :global(.loa-global-theme) .pre-ad-listing-0 {
    display: none;
  }
}
@media only screen and (min-width: 0px) {
  :global(.loa-global-theme) .post-ad-listing-0 {
    display: none;
  }
}
@media only screen and (max-width: 600.9px) {
  :global(.loa-global-theme) .pre-ad-listing-1 {
    display: none;
  }
}
@media only screen and (min-width: 601px) {
  :global(.loa-global-theme) .post-ad-listing-1 {
    display: none;
  }
}
@media only screen and (max-width: 1200.9px) {
  :global(.loa-global-theme) .pre-ad-listing-2 {
    display: none;
  }
}
@media only screen and (min-width: 1201px) {
  :global(.loa-global-theme) .post-ad-listing-2 {
    display: none;
  }
}
@media only screen and (max-width: 1200.9px) {
  :global(.loa-global-theme) .pre-ad-listing-3 {
    display: none;
  }
}
@media only screen and (min-width: 1201px) {
  :global(.loa-global-theme) .post-ad-listing-3 {
    display: none;
  }
}

:global(.lw-global-theme) .header {
  font-family: "Noto Serif", serif;
  font-size: 35px;
  color: #333333;
  font-weight: normal;
  text-align: center;
  display: block;
  margin: 10px 0;
}
@media only screen and (max-width: 600.9px) {
  :global(.lw-global-theme) .header {
    font-size: 28px;
  }
}
:global(.lw-global-theme) .header .header-underline {
  border-top: 1px solid black;
}

:global(.laf-global-theme) .header {
  font-size: 26px;
  font-weight: 400;
  line-height: 50px;
  width: 100%;
  text-align: center;
  font-family: Poppins, sans-serif;
  color: #000;
}
@media only screen and (min-width: 601px) {
  :global(.laf-global-theme) .header {
    font-size: 35px;
  }
}
:global(.laf-global-theme) .header .header-underline {
  border-top: none;
}

:global(.lw-global-theme) .wrapper--showcase {
  display: inline-block;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  vertical-align: top;
}
@media only screen and (min-width: 601px) {
  :global(.lw-global-theme) .wrapper--showcase {
    width: calc(50% - 8px);
    margin: 0 3px;
  }
}
@media only screen and (min-width: 901px) {
  :global(.lw-global-theme) .wrapper--showcase {
    width: calc(33.333% - 10px);
    margin: 0 5px;
  }
}
@media only screen and (min-width: 1401px) {
  :global(.lw-global-theme) .wrapper--showcase {
    width: calc(25% - 10px);
    margin: 0 5px;
  }
}
:global(.lw-global-theme) .header-underline {
  display: block;
  margin: 20px auto 20px auto;
  width: 122px;
  height: 0;
}
@media only screen and (max-width: 600.9px) {
  :global(.lw-global-theme) .header-underline {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: -0.1px) {
  :global(.lw-global-theme) .pre-ad-listing-0 {
    display: none;
  }
}
@media only screen and (min-width: 0px) {
  :global(.lw-global-theme) .post-ad-listing-0 {
    display: none;
  }
}
@media only screen and (max-width: -0.1px) {
  :global(.lw-global-theme) .pre-ad-listing-1 {
    display: none;
  }
}
@media only screen and (min-width: 0px) {
  :global(.lw-global-theme) .post-ad-listing-1 {
    display: none;
  }
}
@media only screen and (max-width: 600.9px) {
  :global(.lw-global-theme) .pre-ad-listing-2 {
    display: none;
  }
}
@media only screen and (min-width: 601px) {
  :global(.lw-global-theme) .post-ad-listing-2 {
    display: none;
  }
}
@media only screen and (max-width: 600.9px) {
  :global(.lw-global-theme) .pre-ad-listing-3 {
    display: none;
  }
}
@media only screen and (min-width: 601px) {
  :global(.lw-global-theme) .post-ad-listing-3 {
    display: none;
  }
}
@media only screen and (max-width: 900.9px) {
  :global(.lw-global-theme) .pre-ad-listing-4 {
    display: none;
  }
}
@media only screen and (min-width: 901px) {
  :global(.lw-global-theme) .post-ad-listing-4 {
    display: none;
  }
}
@media only screen and (max-width: 900.9px) {
  :global(.lw-global-theme) .pre-ad-listing-5 {
    display: none;
  }
}
@media only screen and (min-width: 901px) {
  :global(.lw-global-theme) .post-ad-listing-5 {
    display: none;
  }
}
@media only screen and (max-width: 1200.9px) {
  :global(.lw-global-theme) .pre-ad-listing-6 {
    display: none;
  }
}
@media only screen and (min-width: 1201px) {
  :global(.lw-global-theme) .post-ad-listing-6 {
    display: none;
  }
}
@media only screen and (max-width: 1200.9px) {
  :global(.lw-global-theme) .pre-ad-listing-7 {
    display: none;
  }
}
@media only screen and (min-width: 1201px) {
  :global(.lw-global-theme) .post-ad-listing-7 {
    display: none;
  }
}

:global(.laf-global-theme) .wrapper--showcase {
  display: inline-block;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  vertical-align: top;
}
@media only screen and (min-width: 601px) {
  :global(.laf-global-theme) .wrapper--showcase {
    width: calc(50% - 8px);
    margin: 0 3px;
  }
}
@media only screen and (min-width: 901px) {
  :global(.laf-global-theme) .wrapper--showcase {
    width: calc(33.333% - 10px);
    margin: 0 5px;
  }
}
@media only screen and (min-width: 1401px) {
  :global(.laf-global-theme) .wrapper--showcase {
    width: calc(25% - 10px);
    margin: 0 5px;
  }
}
:global(.laf-global-theme) .header-underline {
  display: block;
  margin: 20px auto 20px auto;
  width: 122px;
  height: 0;
}
@media only screen and (max-width: 600.9px) {
  :global(.laf-global-theme) .header-underline {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: -0.1px) {
  :global(.laf-global-theme) .pre-ad-listing-0 {
    display: none;
  }
}
@media only screen and (min-width: 0px) {
  :global(.laf-global-theme) .post-ad-listing-0 {
    display: none;
  }
}
@media only screen and (max-width: -0.1px) {
  :global(.laf-global-theme) .pre-ad-listing-1 {
    display: none;
  }
}
@media only screen and (min-width: 0px) {
  :global(.laf-global-theme) .post-ad-listing-1 {
    display: none;
  }
}
@media only screen and (max-width: 600.9px) {
  :global(.laf-global-theme) .pre-ad-listing-2 {
    display: none;
  }
}
@media only screen and (min-width: 601px) {
  :global(.laf-global-theme) .post-ad-listing-2 {
    display: none;
  }
}
@media only screen and (max-width: 600.9px) {
  :global(.laf-global-theme) .pre-ad-listing-3 {
    display: none;
  }
}
@media only screen and (min-width: 601px) {
  :global(.laf-global-theme) .post-ad-listing-3 {
    display: none;
  }
}
@media only screen and (max-width: 900.9px) {
  :global(.laf-global-theme) .pre-ad-listing-4 {
    display: none;
  }
}
@media only screen and (min-width: 901px) {
  :global(.laf-global-theme) .post-ad-listing-4 {
    display: none;
  }
}
@media only screen and (max-width: 900.9px) {
  :global(.laf-global-theme) .pre-ad-listing-5 {
    display: none;
  }
}
@media only screen and (min-width: 901px) {
  :global(.laf-global-theme) .post-ad-listing-5 {
    display: none;
  }
}
@media only screen and (max-width: 1200.9px) {
  :global(.laf-global-theme) .pre-ad-listing-6 {
    display: none;
  }
}
@media only screen and (min-width: 1201px) {
  :global(.laf-global-theme) .post-ad-listing-6 {
    display: none;
  }
}
@media only screen and (max-width: 1200.9px) {
  :global(.laf-global-theme) .pre-ad-listing-7 {
    display: none;
  }
}
@media only screen and (min-width: 1201px) {
  :global(.laf-global-theme) .post-ad-listing-7 {
    display: none;
  }
}