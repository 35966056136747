:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.embla__lazy-load__spinner {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  background-color: #fff;
}

.embla__slide {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
  height: auto;
}

.level-diamondMobile,
.level-diamondTablet,
.level-diamondDesktop,
.level-diamondMobile-map,
.level-diamondTablet-map {
  height: 304px;
}
@media only screen and (min-width: 601px) {
  .level-diamondMobile,
  .level-diamondTablet,
  .level-diamondDesktop,
  .level-diamondMobile-map,
  .level-diamondTablet-map {
    height: 360px;
  }
}

@media only screen and (min-width: 990px) and (max-width: 1024px) {
  .level-diamondMobile-map,
  .level-diamondTablet-map {
    height: 304px;
  }
}

.level-diamondDesktop .level-platinum,
.level-headling {
  height: 256px;
}

.level-gold {
  height: 216px;
}

.embla__lazy-load {
  position: relative;
  height: 100%;
}

.embla__slide__img {
  display: block;
  height: var(--slide-height);
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.single-slide-carousel .embla__lazy-load__img {
  opacity: 1;
}
.single-slide-carousel .fade-out .embla__lazy-load__img {
  opacity: 0;
}

.multi-slide-carousel .embla__lazy-load__img {
  opacity: 0;
}
.multi-slide-carousel .embla__lazy-load--has-loaded .embla__lazy-load__img {
  opacity: 1;
}