:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.carousel-pagination {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 8px 16px;
  gap: 8px;
}

.pagination-icon {
  cursor: pointer;
}

.ellipse {
  display: inline-block;
  background: url("/assets/images/ellipse.svg") no-repeat center center;
  width: 8px;
  height: 8px;
  background-size: contain;
}

.ellipse-selected {
  display: inline-block;
  background: url("/assets/images/ellipse-selected.svg") no-repeat center center;
  width: 8px;
  height: 8px;
  background-size: contain;
}