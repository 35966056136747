:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.ctas {
  display: -ms-flexbox;
  display: flex;
  bottom: 10px;
  z-index: 5;
}

:global(.lw-global-theme) .ctas {
  margin-top: 10px;
  margin-left: 10px;
  position: absolute;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-align: center;
      align-items: center;
}

:global(.laf-global-theme) .ctas {
  margin-top: 10px;
  margin-left: 10px;
  position: absolute;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-align: center;
      align-items: center;
}