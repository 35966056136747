:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin-top: 16px;
}

.title {
  color: #302c2d;
  font-family: Athelas;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-top: 0;
  margin-bottom: 8px;
}

.pagination-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}