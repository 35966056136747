:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.amenity-links-container {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-left: 20px;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  margin: 5px 0px 30px;
}

.link-section {
  margin-bottom: 18px;
}
.link-section ul {
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  margin: 0px;
  list-style: none;
}

.amenity-links-header {
  font-size: 16px;
  line-height: 25px;
  font-weight: 700;
  padding-left: 20px;
  margin: 14px 0px;
}

.link {
  font-size: 16px;
  line-height: 24px;
}
.link:hover {
  color: #d3ae48;
}

@media only screen and (min-width: 601px) {
  .amenity-links-container {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 5px;
  }
  .link-section {
    margin-bottom: 25px;
  }
}
@media only screen and (min-width: 901px) {
  .amenity-links-container {
    padding-left: 10px;
    grid-template-columns: repeat(2, 1fr);
  }
  .amenity-links-header {
    padding-left: 10px;
  }
}
@media only screen and (min-width: 1024px) {
  .amenity-links-container {
    margin-bottom: 30px;
  }
  .link-section {
    margin-bottom: 0px;
  }
}
@media only screen and (min-width: 1201px) {
  .amenity-links-container {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and (min-width: 1601px) {
  .amenity-links-container,
  .amenity-links-header {
    padding-left: 0px;
  }
}