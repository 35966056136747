:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.main {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid #cccccc;
  border-radius: 3px;
  padding: 15px 5px 15px 20px;
  display: inline-block;
}

.stat {
  font-weight: 700;
  font-size: 18px;
  display: block;
}

.category {
  font-size: 14px;
  display: block;
  color: #333333;
}

.rank {
  color: #a9a9a9;
  font-size: 14px;
}