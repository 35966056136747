:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.button-search {
  display: none;
}

:global(.lw-global-theme) :global(.Home) .button-search {
  height: 65px;
  min-width: 65px;
  color: #fff;
  background: #fa6401;
  border: solid 2px #fa6401;
  border-radius: 0 3px 3px 0;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
:global(.lw-global-theme) :global(.Home) .button-search:hover {
  -webkit-filter: brightness(90%);
  filter: brightness(90%);
}
:global(.lw-global-theme) :global(.Home) .button-search span {
  background: url(/assets/images/lw-bundle.svg#magnify-button-white) no-repeat center center;
  background-size: 25px 25px;
  width: 100%;
  height: 100%;
}
@media only screen and (min-width: 901px) {
  :global(.lw-global-theme) :global(.Home) .button-search {
    display: none;
  }
}
:global(.lw-global-theme) :global(.Broker-Directory-New) .button-search {
  display: -ms-flexbox;
  display: flex;
  height: 56px;
  padding: 8px 16px;
  -ms-flex-pack: end;
      justify-content: flex-end;
  -ms-flex-align: center;
      align-items: center;
  gap: 4px;
  border-radius: 0px 2px 0px 0px;
  background: var(--LW-Orange, #fa6401);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
:global(.lw-global-theme) :global(.Broker-Directory-New) .button-search:hover {
  -webkit-filter: brightness(90%);
  filter: brightness(90%);
}
:global(.lw-global-theme) :global(.Broker-Directory-New) .button-search span {
  background: url(/assets/images/lw-bundle.svg#lw-broker-search-icon) no-repeat center center;
  background-size: 25px 25px;
  width: 24px;
  height: 24px;
}
:global(.lw-global-theme) :global(.Broker-Directory-New) .seller-directory-search-results.button-search {
  height: 40px;
  width: 40px;
  padding: 0px 8px;
  border-radius: 0px 6px 6px 0px;
}

:global(.laf-global-theme) :global(.Broker-Directory-New) .button-search {
  display: -ms-flexbox;
  display: flex;
  height: 56px;
  padding: 8px 16px;
  -ms-flex-pack: end;
      justify-content: flex-end;
  -ms-flex-align: center;
      align-items: center;
  gap: 4px;
  border-radius: 0px 2px 0px 0px;
  background: #d3ae48;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
:global(.laf-global-theme) :global(.Broker-Directory-New) .button-search:hover {
  -webkit-filter: brightness(90%);
  filter: brightness(90%);
}
:global(.laf-global-theme) :global(.Broker-Directory-New) .button-search span {
  background-size: 25px 25px;
  background: url(/assets/images/search-icon-seller-directory.svg) no-repeat center center;
  height: 24px;
  width: 24px;
}
:global(.laf-global-theme) :global(.Broker-Directory-New) .seller-directory-search-results.button-search {
  height: 40px;
  width: 40px;
  padding: 0px 8px;
  border-radius: 0px 6px 6px 0px;
}

:global(.loa-global-theme) .magnify {
  display: inline-block;
  background: url("/assets/images/land-bundle.svg#magnifying-glass") no-repeat center center;
  width: 18px;
  height: 19px;
  background-size: contain;
  padding: 1px;
}
:global(.loa-global-theme) .button-search {
  top: 0;
  right: 1px;
  height: 100%;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  position: absolute;
}
:global(.loa-global-theme) .magnify_active,
:global(.loa-global-theme) .magnify_icon {
  display: inline-block;
  background: url("/assets/images/magnify-dark.svg") no-repeat center center;
  width: 25px;
  height: 25px;
  background-size: contain;
}
:global(.loa-global-theme) .magnify_active {
  display: inline-block;
  background: url("/assets/images/magnify-blue.svg") no-repeat center center;
  width: 25px;
  height: 25px;
  background-size: contain;
}
:global(.loa-global-theme) :global(.PropertySearch) .magnify_icon,
:global(.loa-global-theme) :global(.PropertySearch) .magnify_active {
  margin-right: 8px;
}
:global(.loa-global-theme) :global(.Home) .button-search {
  height: 40px;
  width: 40px;
  top: 4px;
  right: 8px;
  z-index: 3;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #006d31;
  cursor: pointer;
  border-radius: 50%;
  border: 0 0 1px solid #006d31 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  position: absolute;
}
@media only screen and (min-width: 901px) {
  :global(.loa-global-theme) :global(.Home) .button-search {
    display: none;
  }
}
:global(.loa-global-theme) :global(.Broker-Directory-New) .button-search {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 40px;
  height: 40px;
  padding: 8px;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  gap: var(--spacing-8, 8px);
  margin-right: 8px;
  top: 8px;
  border-radius: 60px;
  background: var(--MH-Archive-Green-80---Primary, #006d31);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
:global(.loa-global-theme) :global(.Broker-Directory-New) .button-search .magnify_icon {
  margin: auto;
  width: 19px;
  height: 19px;
  background: url("/assets/images/search-icon-seller-page.svg") no-repeat center center;
}
:global(.loa-global-theme) :global(.Broker-Directory-New) .button-search .magnify_icon.focus-visible {
  outline: none;
}
:global(.loa-global-theme) :global(.Broker-Directory-New) .seller-directory-search-results.button-search {
  background-color: white;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  border: none;
  height: 22px;
  padding: 0px;
  width: 22px;
}
:global(.loa-global-theme) :global(.Broker-Directory-New) .seller-directory-search-results.button-search .magnify_icon {
  display: inline-block;
  background: url("/assets/images/search-icon-seller-directory.svg") no-repeat center center;
  width: 22px;
  height: 22px;
  background-size: contain;
}