:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

:global(.PropertySearch) .level-free.image-or-carousel-container-map, :global(.PropertySearch) .level-free.image-or-carousel-container,
:global(.PropertySearch) .level-free-map-click-card.image-or-carousel-container-map,
:global(.PropertySearch) .level-free-map-click-card.image-or-carousel-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 104px;
}
:global(.PropertySearch) .carousel-container {
  display: none;
  height: inherit;
  width: 100%;
}
:global(.PropertySearch) .image-container {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
}
:global(.PropertySearch) .level-diamond .carousel-container,
:global(.PropertySearch) .level-platinum .carousel-container,
:global(.PropertySearch) .level-gold .carousel-container {
  display: block;
  position: absolute;
  z-index: 1;
}
:global(.PropertySearch) .level-diamond .image-container {
  position: absolute;
  width: 100%;
}
@media only screen and (min-width: 601px) {
  :global(.PropertySearch) .level-platinum .carousel-container,
  :global(.PropertySearch) .level-platinum .image-container {
    width: 368px;
  }
  :global(.PropertySearch) .level-gold .carousel-container,
  :global(.PropertySearch) .level-gold .image-container {
    width: 320px;
  }
}
:global(.PropertySearch) .level-diamond.image-or-carousel-container {
  width: 100%;
  height: 304px;
}
@media only screen and (min-width: 601px) {
  :global(.PropertySearch) .level-diamond.image-or-carousel-container {
    height: 360px;
  }
}
:global(.PropertySearch) .level-diamond.image-or-carousel-container-map {
  width: 100%;
  height: 304px;
}
@media only screen and (min-width: 1025px) {
  :global(.PropertySearch) .level-diamond.image-or-carousel-container-map {
    height: 360px;
  }
}
@media only screen and (min-width: 601px) {
  :global(.PropertySearch) .level-free.image-or-carousel-container-map, :global(.PropertySearch) .level-free.image-or-carousel-container {
    width: unset;
  }
}
@media only screen and (min-width: 990px) and (max-width: 1024px) {
  :global(.PropertySearch) .level-free.image-or-carousel-container-map {
    width: 104px;
  }
  :global(.PropertySearch) .level-gold.image-or-carousel-container-map .carousel-container,
  :global(.PropertySearch) .level-gold.image-or-carousel-container-map .image-container {
    width: 100%;
  }
  :global(.PropertySearch) .level-diamond.image-or-carousel-container-map {
    height: 304px;
  }
}