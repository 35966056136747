:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

/* Common properties */
.carousel {
  margin: 20px 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 0px 15px;
}
@media only screen and (min-width: 901px) {
  .carousel {
    padding: 0px 30px;
    max-width: calc(1601px + 60px);
  }
}

.hero-section {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
}

.section {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
@media only screen and (min-width: 601px) {
  .section {
    max-width: calc(1601px + 60px);
    margin-top: 0;
    padding: 0 30px;
  }
}

.main {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-direction: column;
      flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 100vh;
}
.main:focus {
  outline: 0;
}

:global(.lw-global-theme) .hero-section {
  height: 228px;
}
@media only screen and (min-width: 601px) {
  :global(.lw-global-theme) .hero-section {
    height: 245px;
  }
}
@media only screen and (min-width: 901px) {
  :global(.lw-global-theme) .hero-section {
    height: 315px;
  }
}
@media only screen and (min-width: 901px) {
  :global(.lw-global-theme) .section {
    max-width: calc(1601px + 60px);
    margin-top: 0;
    padding: 0 30px;
  }
}
:global(.lw-global-theme) .carousel {
  height: 330px;
}
@media only screen and (min-width: 901px) {
  :global(.lw-global-theme) .carousel {
    height: 336px;
  }
}
:global(.lw-global-theme) .advertise-section {
  min-height: 642px;
}
@media only screen and (min-width: 601px) {
  :global(.lw-global-theme) .advertise-section {
    min-height: 580px;
  }
}
@media only screen and (min-width: 901px) {
  :global(.lw-global-theme) .advertise-section {
    min-height: 524px;
  }
}
@media only screen and (min-width: 1401px) {
  :global(.lw-global-theme) .advertise-section {
    min-height: 491px;
  }
}
:global(.lw-global-theme) .add-listing-section {
  min-height: 645px;
}
@media only screen and (min-width: 601px) {
  :global(.lw-global-theme) .add-listing-section {
    min-height: 669px;
  }
}
@media only screen and (min-width: 901px) {
  :global(.lw-global-theme) .add-listing-section {
    min-height: 734px;
  }
}
@media only screen and (min-width: 1025px) {
  :global(.lw-global-theme) .add-listing-section {
    min-height: 696px;
  }
}
@media only screen and (min-width: 1401px) {
  :global(.lw-global-theme) .add-listing-section {
    min-height: 491px;
  }
}

:global(.laf-global-theme) .hero-section {
  height: 269px;
}
@media only screen and (min-width: 601px) {
  :global(.laf-global-theme) .hero-section {
    height: 338px;
  }
}
@media only screen and (min-width: 901px) {
  :global(.laf-global-theme) .hero-section {
    height: 355px;
  }
}
:global(.laf-global-theme) .section {
  padding: 0 8px;
}
:global(.laf-global-theme) .section.property-grid-container {
  margin-top: 32px;
}
@media only screen and (min-width: 375px) {
  :global(.laf-global-theme) .section {
    padding: 0 16px;
  }
}
@media only screen and (min-width: 601px) {
  :global(.laf-global-theme) .section {
    padding: 0 24px;
  }
  :global(.laf-global-theme) .section.property-grid-container {
    height: 620px;
  }
}
@media only screen and (min-width: 901px) {
  :global(.laf-global-theme) .section.property-grid-container {
    height: 629px;
  }
}
@media only screen and (min-width: 1024px) {
  :global(.laf-global-theme) .section {
    padding: 0 32px;
  }
}
@media only screen and (min-width: 1201px) {
  :global(.laf-global-theme) .section.property-grid-container {
    height: 357px;
  }
}
:global(.laf-global-theme) .carousel {
  height: 384px;
}
@media only screen and (min-width: 901px) {
  :global(.laf-global-theme) .carousel {
    height: 362px;
  }
}
@media only screen and (min-width: 1601px) {
  :global(.laf-global-theme) .carousel {
    height: 372px;
  }
}
:global(.laf-global-theme) .advertise-section {
  width: 100vw;
  background-color: #173f3f;
  background-image: url("/assets/images/map-lines-inverse.webp");
  background-blend-mode: hard-light;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
  min-height: 405px;
}
@media only screen and (min-width: 601px) {
  :global(.laf-global-theme) .advertise-section {
    min-height: 622px;
  }
}
@media only screen and (min-width: 901px) {
  :global(.laf-global-theme) .advertise-section {
    min-height: 586px;
  }
}
@media only screen and (min-width: 1200px) {
  :global(.laf-global-theme) .advertise-section {
    min-height: 632px;
  }
}
:global(.laf-global-theme) .add-listing-section {
  min-height: 737px;
}
@media only screen and (min-width: 601px) {
  :global(.laf-global-theme) .add-listing-section {
    min-height: 680px;
  }
}
@media only screen and (min-width: 1025px) {
  :global(.laf-global-theme) .add-listing-section {
    min-height: 632px;
  }
}
@media only screen and (min-width: 1200px) {
  :global(.laf-global-theme) .add-listing-section {
    min-height: 430px;
  }
}