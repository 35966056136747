:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.container .toggleIcon {
  padding: 0;
}

.toggleIcon {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  background: none;
  border: none;
  padding: 0;
}
@media (hover: hover) and (pointer: fine) {
  .toggleIcon:hover .unsaved-icon {
    display: inline-block;
    background: url("/assets/images/shared-bundle.svg#heart-red-filled") no-repeat center center;
    width: 32px;
    height: 32px;
    background-size: contain;
  }
  .toggleIcon:hover .envelope-icon {
    display: inline-block;
    background: url("/assets/images/land-bundle.svg#envelope-filled") no-repeat center center;
    width: 32px;
    height: 32px;
    background-size: contain;
  }
}

.icon {
  top: 1px;
  right: 6px;
}

.saved-icon {
  display: inline-block;
  background: url("/assets/images/shared-bundle.svg#heart-red-filled") no-repeat center center;
  width: 32px;
  height: 32px;
  background-size: contain;
}

.icon:active {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.icon:active.unsaved-icon {
  display: inline-block;
  background: url("/assets/images/shared-bundle.svg#heart-red-filled") no-repeat center center;
  width: 32px;
  height: 32px;
  background-size: contain;
}
.icon:active.envelope-icon {
  display: inline-block;
  background: url("/assets/images/land-bundle.svg#envelope-filled") no-repeat center center;
  width: 32px;
  height: 32px;
  background-size: contain;
}

.unsaved-icon {
  display: inline-block;
  background: url("/assets/images/shared-bundle.svg#heart-red-stroked") no-repeat center center;
  width: 32px;
  height: 32px;
  background-size: contain;
}

.envelope-icon {
  display: inline-block;
  background: url("/assets/images/land-bundle.svg#envelope-stroked") no-repeat center center;
  width: 32px;
  height: 32px;
  background-size: contain;
}