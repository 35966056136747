:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.hero-container {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 400px;
  max-height: 400px;
  max-width: 2560px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
}
.hero-container .hero-image {
  position: absolute;
  -o-object-fit: cover;
     object-fit: cover;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.hero-container .content-area {
  z-index: 1;
  height: 100%;
  width: 100%;
  max-width: 855px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  padding: 16px;
}
.hero-container .content-area .headers {
  color: #fff;
  text-align: center;
}
.hero-container .content-area .headers .mainheader {
  font-family: Athelas, serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}
.hero-container .content-area .headers .subheader {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.hero-container .content-area .headers span.avoidwrap {
  display: inline-block;
  white-space: pre;
}
.hero-container .content-area .search-bar {
  position: relative;
  min-height: 48px;
  width: 100%;
  max-width: 488px;
  margin: 0px auto;
}
.hero-container .dark-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(14, 13, 12, 0.2);
}

@media only screen and (min-width: 601px) {
  .hero-container .content-area .headers .mainheader {
    font-size: 48px;
    line-height: 56px;
  }
}
@media only screen and (min-width: 1025px) {
  .hero-container {
    height: 480px;
    max-height: 480px;
  }
}