:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.article-group-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-item-align: stretch;
      align-self: stretch;
}

.header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: end;
      align-items: flex-end;
  gap: 10px;
  -ms-flex-item-align: stretch;
      align-self: stretch;
}

.header-text {
  -ms-flex: 1 0;
      flex: 1 0;
  margin: 0;
  font-family: Athelas, serif;
  font-size: 24px;
  line-height: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
  text-align: center;
}
@media only screen and (min-width: 601px) {
  .header-text {
    font-size: 32px;
    line-height: 40px;
  }
}

.button-container .view-articles-button {
  color: #0e7080;
  font-family: Roboto, "Franklin Gothic Medium", Tahoma, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  line-height: 24px;
  -ms-flex-item-align: center;
      align-self: center;
  padding: 12px 32px;
  height: auto;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  border: solid 1px #fff;
  border-radius: 6px;
  color: #fff;
  margin: 24px 0 0 0;
}

.article-grid {
  display: grid;
  padding: 16px 0;
  width: 100%;
  grid-gap: 16px;
  min-height: 428px;
  grid-auto-rows: 0;
  padding-bottom: 0;
  row-gap: 0;
  overflow: hidden;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.article-grid > div {
  display: none;
}
.article-grid > div:nth-child(1) {
  display: -ms-flexbox;
  display: flex;
}
@media only screen and (min-width: 601px) {
  .article-grid {
    gap: 16px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .article-grid > div:nth-child(-n+2) {
    display: -ms-flexbox;
    display: flex;
  }
}
@media only screen and (min-width: 901px) {
  .article-grid {
    gap: 16px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .article-grid > div:nth-child(-n+3) {
    display: -ms-flexbox;
    display: flex;
  }
}
@media only screen and (min-width: 1025px) {
  .article-grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .article-grid > div {
    display: -ms-flexbox;
    display: flex;
  }
}
.article-grid p {
  margin: 0;
}