:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

/* Common properties */
:global(.loa-global-theme) .location-display-button-wrap {
  background: none;
  width: 100%;
  height: 35px;
  text-align: left;
  padding: 0 0 0 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  color: #333333;
  font-family: Roboto, "Franklin Gothic Medium", Tahoma, sans-serif;
  font-size: 18px;
  line-height: 18px;
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}
:global(.loa-global-theme) .location-display-button-wrap .location--button-open {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: transparent;
  border-radius: 3px;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  cursor: pointer;
  font-size: 1em;
  height: 48px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  padding: 0 10px;
  text-align: center;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  margin: 0;
  padding: 0;
  -ms-flex-pack: start;
      justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  color: #333333;
  font-size: 14px;
  cursor: text;
  width: 100%;
}
:global(.loa-global-theme) .location-display-button-wrap .location--button-open:focus {
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
:global(.loa-global-theme) .location-display-button-wrap .location--button-open:disabled {
  opacity: 0.4;
}
:global(.loa-global-theme) :global(.Home) .location-display-button-wrap {
  height: 48px;
  border: none;
}
@media only screen and (min-width: 601px) {
  :global(.loa-global-theme) :global(.Home) .location-display-button-wrap .location--button-open {
    font-size: 16px;
  }
}

:global(.lw-global-theme) .location--button-open {
  font-family: "Noto Serif", serif;
}
:global(.lw-global-theme) .location-display-button-wrap .location--button-open {
  font-family: "Noto Serif", serif;
}

:global(.laf-global-theme) .location-display-button-wrap .location--button-open {
  color: #333333;
  font-family: Poppins, sans-serif;
}
:global(.laf-global-theme) :global(.Home) {
  font-family: Poppins, sans-serif;
}

:global(.lw-global-theme) .location-display-button-wrap {
  width: 100%;
  text-align: left;
  padding: 0 0 0 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  color: #333333;
  font-family: Roboto, "Franklin Gothic Medium", Tahoma, sans-serif;
  font-size: 18px;
  line-height: 18px;
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  background-color: #dddddd;
  color: #333333;
  height: 50px;
  border-radius: 3px 0 0 3px;
  border: none;
}
:global(.lw-global-theme) .location-display-button-wrap .magnify-icon {
  margin-right: 15px;
  margin-left: 10px;
  display: inline-block;
  background: url("/assets/images/shared-bundle.svg#magnify-icon") no-repeat center center;
  width: 25px;
  height: 25px;
  background-size: contain;
  background-size: 25px 25px;
}
@media only screen and (max-width: 900.9px) {
  :global(.lw-global-theme) .location-display-button-wrap .magnify-icon {
    background: none;
    display: none;
  }
}
:global(.lw-global-theme) .location-display-button-wrap .location--button-open {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: transparent;
  border-radius: 3px;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  cursor: pointer;
  font-size: 1em;
  height: 48px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  padding: 0 10px;
  text-align: center;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  margin: 0;
  padding: 0;
  -ms-flex-pack: start;
      justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  color: #333333;
  font-size: 14px;
  cursor: text;
  width: 100%;
  background-color: #dddddd;
  color: #333333;
  height: 50px;
  font-size: 24px;
  border-radius: 3px 0 0 3px;
}
:global(.lw-global-theme) .location-display-button-wrap .location--button-open:focus {
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
:global(.lw-global-theme) .location-display-button-wrap .location--button-open:disabled {
  opacity: 0.4;
}
@media only screen and (max-width: 900.9px) {
  :global(.lw-global-theme) .location-display-button-wrap .location--button-open {
    padding-left: 11px;
  }
}
@media only screen and (max-width: 900.9px) {
  :global(.lw-global-theme) .location-display-button-wrap .location--button-open {
    font-size: 16px;
  }
}
@media only screen and (max-width: 600.9px) {
  :global(.lw-global-theme) .location-display-button-wrap .location--button-open {
    font-size: 14px;
  }
}
@media only screen and (max-width: 374.9px) {
  :global(.lw-global-theme) .location-display-button-wrap .location--button-open {
    font-size: 14px;
  }
}
@media only screen and (max-width: 900.9px) {
  :global(.lw-global-theme) .location-display-button-wrap .location--button-open > span:last-of-type {
    font-size: 16px;
  }
}
@media only screen and (max-width: 600.9px) {
  :global(.lw-global-theme) .location-display-button-wrap .location--button-open > span:last-of-type {
    font-size: 12px;
  }
}
@media only screen and (max-width: 374.9px) {
  :global(.lw-global-theme) .location-display-button-wrap .location--button-open > span:last-of-type {
    font-size: 12px;
  }
}
:global(.lw-global-theme) :global(.Home) .location-display-button-wrap {
  height: 65px;
}
:global(.lw-global-theme) :global(.Home) .location-display-button-wrap .location--button-open {
  font-size: 24px;
}
@media only screen and (max-width: 900.9px) {
  :global(.lw-global-theme) :global(.Home) .location-display-button-wrap .location--button-open {
    font-size: 18px;
  }
}
@media only screen and (max-width: 600.9px) {
  :global(.lw-global-theme) :global(.Home) .location-display-button-wrap .location--button-open {
    font-size: 16px;
  }
}
@media only screen and (max-width: 374.9px) {
  :global(.lw-global-theme) :global(.Home) .location-display-button-wrap .location--button-open {
    font-size: 16px;
  }
}

:global(.laf-global-theme) .location-display-button-wrap {
  width: 100%;
  text-align: left;
  padding: 0 0 0 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  color: #333333;
  font-family: Roboto, "Franklin Gothic Medium", Tahoma, sans-serif;
  font-size: 18px;
  line-height: 18px;
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  background-color: #dddddd;
  color: #333333;
  height: 50px;
  border-radius: 3px 0 0 3px;
  border: none;
}
:global(.laf-global-theme) .location-display-button-wrap .magnify-icon {
  margin-right: 15px;
  margin-left: 10px;
  display: inline-block;
  background: url("/assets/images/shared-bundle.svg#magnify-icon") no-repeat center center;
  width: 25px;
  height: 25px;
  background-size: contain;
  background-size: 25px 25px;
}
@media only screen and (max-width: 900.9px) {
  :global(.laf-global-theme) .location-display-button-wrap .magnify-icon {
    background: none;
    display: none;
  }
}
:global(.laf-global-theme) .location-display-button-wrap .location--button-open {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: transparent;
  border-radius: 3px;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  cursor: pointer;
  font-size: 1em;
  height: 48px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  padding: 0 10px;
  text-align: center;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  margin: 0;
  padding: 0;
  -ms-flex-pack: start;
      justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  color: #333333;
  font-size: 14px;
  cursor: text;
  width: 100%;
  background-color: #dddddd;
  color: #333333;
  height: 50px;
  font-size: 24px;
  border-radius: 3px 0 0 3px;
}
:global(.laf-global-theme) .location-display-button-wrap .location--button-open:focus {
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
:global(.laf-global-theme) .location-display-button-wrap .location--button-open:disabled {
  opacity: 0.4;
}
@media only screen and (max-width: 900.9px) {
  :global(.laf-global-theme) .location-display-button-wrap .location--button-open {
    padding-left: 11px;
  }
}
@media only screen and (max-width: 900.9px) {
  :global(.laf-global-theme) .location-display-button-wrap .location--button-open {
    font-size: 16px;
  }
}
@media only screen and (max-width: 600.9px) {
  :global(.laf-global-theme) .location-display-button-wrap .location--button-open {
    font-size: 14px;
  }
}
@media only screen and (max-width: 374.9px) {
  :global(.laf-global-theme) .location-display-button-wrap .location--button-open {
    font-size: 14px;
  }
}
@media only screen and (max-width: 900.9px) {
  :global(.laf-global-theme) .location-display-button-wrap .location--button-open > span:last-of-type {
    font-size: 16px;
  }
}
@media only screen and (max-width: 600.9px) {
  :global(.laf-global-theme) .location-display-button-wrap .location--button-open > span:last-of-type {
    font-size: 12px;
  }
}
@media only screen and (max-width: 374.9px) {
  :global(.laf-global-theme) .location-display-button-wrap .location--button-open > span:last-of-type {
    font-size: 12px;
  }
}
:global(.laf-global-theme) :global(.Home) .location-display-button-wrap {
  height: 65px;
}
:global(.laf-global-theme) :global(.Home) .location-display-button-wrap .location--button-open {
  font-size: 24px;
}
@media only screen and (max-width: 900.9px) {
  :global(.laf-global-theme) :global(.Home) .location-display-button-wrap .location--button-open {
    font-size: 18px;
  }
}
@media only screen and (max-width: 600.9px) {
  :global(.laf-global-theme) :global(.Home) .location-display-button-wrap .location--button-open {
    font-size: 16px;
  }
}
@media only screen and (max-width: 374.9px) {
  :global(.laf-global-theme) :global(.Home) .location-display-button-wrap .location--button-open {
    font-size: 16px;
  }
}