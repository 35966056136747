:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.broker-name,
.broker-company {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.placard-broker {
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  font-size: 14px;
  line-height: 18px;
  -ms-flex-align: end;
      align-items: flex-end;
}
.placard-broker .placard-broker-brand {
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 40px;
  height: 40px;
  margin: 0 8px 0 0;
}
.placard-broker .placard-broker-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 1 1;
      flex: 1 1;
  overflow: hidden;
  -ms-flex-item-align: center;
      align-self: center;
  -ms-flex-pack: center;
      justify-content: center;
  height: 100%;
  margin-right: 16px;
}
.placard-broker .placard-broker-info .broker-name {
  font-weight: 500;
}
.placard-broker .placard-broker-info a:hover {
  color: #333;
  text-decoration: none;
}
.placard-broker .portrait {
  width: 100%;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
}

:global(.PropertySearch) .placard-broker.level-premium, :global(.PropertySearch) .placard-broker.level-standard, :global(.PropertySearch) .placard-broker.level-signature, :global(.PropertySearch) .placard-broker.level-showcase, :global(.PropertySearch) .placard-broker.level-gold, :global(.PropertySearch) .placard-broker.level-platinum, :global(.PropertySearch) .placard-broker.level-free {
  min-height: auto;
}
:global(.PropertySearch) .placard-broker.level-premium .placard-broker-brand, :global(.PropertySearch) .placard-broker.level-standard .placard-broker-brand, :global(.PropertySearch) .placard-broker.level-signature .placard-broker-brand, :global(.PropertySearch) .placard-broker.level-showcase .placard-broker-brand, :global(.PropertySearch) .placard-broker.level-gold .placard-broker-brand, :global(.PropertySearch) .placard-broker.level-platinum .placard-broker-brand, :global(.PropertySearch) .placard-broker.level-free .placard-broker-brand {
  margin: 0 8px 0 0;
}

@media only screen and (min-width: 601px) {
  :global(.loa-global-theme) :global(.PropertySearch) .level-premium .contact,
  :global(.loa-global-theme) :global(.PropertySearch) .level-standard .contact,
  :global(.loa-global-theme) :global(.PropertySearch) .level-signature .contact,
  :global(.loa-global-theme) :global(.PropertySearch) .level-showcase .contact,
  :global(.loa-global-theme) :global(.PropertySearch) .level-gold .contact,
  :global(.loa-global-theme) :global(.PropertySearch) .level-platinum .contact,
  :global(.loa-global-theme) :global(.PropertySearch) .level-free .contact,
  :global(.loa-global-theme) :global(.PropertySearch) .level-diamond .contact {
    display: none;
  }
}
@media only screen and (min-width: 990px) and (max-width: 1024px) {
  :global(.loa-global-theme) :global(.PropertySearch) .level-premium.map-view .contact,
  :global(.loa-global-theme) :global(.PropertySearch) .level-standard.map-view .contact,
  :global(.loa-global-theme) :global(.PropertySearch) .level-signature.map-view .contact,
  :global(.loa-global-theme) :global(.PropertySearch) .level-showcase.map-view .contact,
  :global(.loa-global-theme) :global(.PropertySearch) .level-gold.map-view .contact,
  :global(.loa-global-theme) :global(.PropertySearch) .level-platinum.map-view .contact,
  :global(.loa-global-theme) :global(.PropertySearch) .level-free.map-view .contact {
    display: block;
  }
}

:global(.lw-global-theme) .placard-broker .placard-broker-brand {
  border: 1px solid black;
  border-radius: 3px;
  margin-right: 10px;
  width: 35px;
  height: 35px;
}
:global(.lw-global-theme) .placard-broker .broker-company,
:global(.lw-global-theme) .placard-broker .broker-name {
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}
:global(.lw-global-theme) .placard-broker .broker-name {
  font-weight: 700;
}

:global(.laf-global-theme) .placard-broker .broker-name {
  font-weight: 600;
}

:global(.lw-global-theme) .placard-broker .placard-broker-brand {
  border: 1px solid black;
  border-radius: 3px;
  margin-right: 10px;
  width: 35px;
  height: 35px;
}
:global(.lw-global-theme) .placard-broker .broker-company,
:global(.lw-global-theme) .placard-broker .broker-name {
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}
:global(.lw-global-theme) .placard-broker .broker-name {
  font-weight: 700;
}

:global(.laf-global-theme) .placard-broker .placard-broker-brand {
  border: 1px solid black;
  border-radius: 3px;
  margin-right: 10px;
  width: 35px;
  height: 35px;
}
:global(.laf-global-theme) .placard-broker .broker-company,
:global(.laf-global-theme) .placard-broker .broker-name {
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}
:global(.laf-global-theme) .placard-broker .broker-name {
  font-weight: 700;
}