:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.placard-header-info {
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -ms-flex: 1 1;
      flex: 1 1;
  padding: 0;
}

.placard-detail {
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 1 1;
      flex: 1 1;
}

.address {
  line-height: 18px;
  margin: 0 0 4px;
}

.address1 {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  color: #302c2d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.3px;
}
@media only screen and (min-width: 601px) {
  .address1 {
    display: -ms-flexbox;
    display: flex;
  }
}

.placard-header-title {
  margin: 0 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  text-transform: capitalize;
}

.placard-header-body {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding: 12px;
  -ms-flex-direction: column;
      flex-direction: column;
}

:global(.loa-global-theme) .placard-header-body {
  padding: 12px 16px;
}
:global(.loa-global-theme) .placard-header-title,
:global(.loa-global-theme) .placard-header-title a {
  color: #0e0d0c;
  font-family: Athelas, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

:global(.laf-global-theme) .placard-header-title a:hover {
  color: #000;
  text-decoration: none;
}