:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.placard-body {
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding: 12px;
  font-size: 14px;
  line-height: 18px;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 1 1;
      flex: 1 1;
  min-height: 145px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

:global(.PropertySearch) .placard-body.level-premium, :global(.PropertySearch) .placard-body.level-standard, :global(.PropertySearch) .placard-body.level-signature, :global(.PropertySearch) .placard-body.level-showcase, :global(.PropertySearch) .placard-body.level-gold, :global(.PropertySearch) .placard-body.level-platinum, :global(.PropertySearch) .placard-body.level-free {
  min-height: auto;
}
:global(.PropertySearch) .level-free.placard-body {
  padding: 8px;
}
:global(.PropertySearch) .level-diamond.placard-body {
  min-height: 160px;
  padding: 12px;
}
@media only screen and (min-width: 601px) {
  :global(.PropertySearch) .level-free.placard-body {
    padding: 12px;
  }
}

@media only screen and (min-width: list-view-map-view-screen) {
  .placard-body {
    padding: 16px;
  }
}
:global(.loa-global-theme) :global(.PropertySearch) .level-diamond.placard-body {
  min-height: 96px;
}

.level-showcase-map-click-card.placard-body {
  min-height: 96px;
  height: auto;
}

.level-free-map-click-card.placard-body {
  padding: 8px;
  min-height: auto;
}