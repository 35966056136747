:export {
  lafYellow: #d3ae48;
  lwOrange: #fa6401;
  lwGray: #dddddd;
}

.header {
  font-family: Athelas, serif;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  color: #302c2d;
}

.wrapper--showcase {
  display: inline-block;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  vertical-align: top;
}

@media only screen and (max-width: 600.9px) {
  .dynamic-ad-home {
    min-height: 370px;
  }
}
@media only screen and (max-width: 374.9px) {
  .dynamic-ad-home {
    min-height: 337px;
  }
}

.inner-section {
  padding: 0px;
  width: 100%;
  margin: 0px auto 16px auto;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  margin-bottom: 16px;
}

@media only screen and (min-width: 601px) {
  :global(.loa-global-theme) .inner-section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media only screen and (min-width: 1201px) {
  :global(.loa-global-theme) .inner-section {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    max-width: none;
    width: 100%;
  }
}

:global(.lw-global-theme) .carousel {
  width: 100%;
  margin: 0 auto;
  height: auto;
  min-height: 352px;
  height: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 1px;
}
@media only screen and (min-width: 601px) {
  :global(.lw-global-theme) .carousel {
    height: auto;
  }
}
:global(.lw-global-theme) .dynamic-ad-home {
  height: 100%;
}
:global(.lw-global-theme) .carousel .dynamic-ad-home {
  min-height: 0px;
}
@media only screen and (min-width: 601px) {
  :global(.lw-global-theme) .inner-section {
    max-width: 552px;
  }
}
@media only screen and (min-width: 901px) {
  :global(.lw-global-theme) .inner-section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    max-width: 708px;
  }
}
@media only screen and (min-width: 990px) {
  :global(.lw-global-theme) .inner-section {
    max-width: 800px;
  }
}
@media only screen and (min-width: 1201px) {
  :global(.lw-global-theme) .inner-section {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    max-width: none;
    width: 100%;
  }
}

:global(.laf-global-theme) .carousel {
  width: 100%;
  margin: 0 auto;
  height: auto;
  min-height: 352px;
  height: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 1px;
}
@media only screen and (min-width: 601px) {
  :global(.laf-global-theme) .carousel {
    height: auto;
  }
}
:global(.laf-global-theme) .dynamic-ad-home {
  height: 100%;
}
:global(.laf-global-theme) .carousel .dynamic-ad-home {
  min-height: 0px;
}
@media only screen and (min-width: 601px) {
  :global(.laf-global-theme) .inner-section {
    max-width: 552px;
  }
}
@media only screen and (min-width: 901px) {
  :global(.laf-global-theme) .inner-section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    max-width: 708px;
  }
}
@media only screen and (min-width: 990px) {
  :global(.laf-global-theme) .inner-section {
    max-width: 800px;
  }
}
@media only screen and (min-width: 1201px) {
  :global(.laf-global-theme) .inner-section {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    max-width: none;
    width: 100%;
  }
}

:global(.lw-global-theme) .header {
  font-family: "Noto Serif", serif;
  color: #302c2d;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin: 0px 0px 12px 0px;
}
:global(.lw-global-theme) .header h2 {
  margin: 0px;
}
:global(.lw-global-theme) .carousel {
  max-width: 1440px;
  width: calc(100% + 15px);
}
@media only screen and (min-width: 601px) {
  :global(.lw-global-theme) .carousel {
    width: 100%;
  }
}

:global(.laf-global-theme) .header {
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 33px;
  color: #000;
  font-family: Poppins, sans-serif;
}